import styled from "styled-components";

const SideNavStyles = styled.div`
  .ant-menu-sub,
  .ant-menu-item-selected {
    background-color: #fff !important;
  }

  .ant-menu-light.ant-menu-root.ant-menu-inline {
    border-inline-end-width: 2px;
  }

  .ant-row {
    background-color: #fff;
  }
`;

export default SideNavStyles;
