import styled from "styled-components";

const ParticipatingEntitiesContainer = styled.div`
  .ant-layout {
    background: #fff;
  }

  .ant-carousel {
    width: 100%;
  }

  .slick-slide.slick-active.slick-current,
  .slick-slide.slick-active {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100%;
    width: 100%;
  }
`;

export default ParticipatingEntitiesContainer;
