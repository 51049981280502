import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Layout, Card, Typography, Row, Col } from "antd";

import UserTypeFormContainer from "./styles";

import CompanyTypeImg from "../../assets/images/company.svg";
import ParticipantTypeImg from "../../assets/images/participant.svg";

const { Content } = Layout;
const { Title, Text } = Typography;

const UserTypeForm = () => {
  const { t } = useTranslation();

  return (
    <UserTypeFormContainer>
      <Layout className="layout-default ant-layout layout-sign-up">
        <Content className="pt-0">
          <Title
            className="mb-3 mt-0"
            style={{
              fontSize: "33px",
              whiteSpace: "break-spaces",
              textAlign: "center",
            }}
          >
            {t("auth.userType.title")}
          </Title>

          <Row justify="center" align="middle" gutter={[16, 16]} xs={{ gutter: 8 }}>
            <Col xs={24} md={8}>
              <Link
                to="/sign-up/participant"
                style={{
                  textDecoration: "none",
                }}
              >
                <Card style={{ width: "100%", height: "280px" }}>
                  <div style={{ textAlign: "center" }}>
                    {/* Center the image */}
                    <img
                      src={ParticipantTypeImg}
                      alt=""
                      style={{ maxWidth: "100%", maxHeight: "100%", height: "150px" }}
                    />
                  </div>
                  <Text
                    className="mt-3"
                    style={{
                      fontSize: "33px",
                      fontWeight: "bold",
                      whiteSpace: "break-spaces",
                      textAlign: "center",
                      display: "block",
                    }}
                  >
                    {t("auth.userType.participant")}
                  </Text>
                </Card>
              </Link>
            </Col>

            <Col xs={24} md={8}>
              <Link
                to="/sign-up/company"
                style={{
                  textDecoration: "none",
                }}
              >
                <Card style={{ width: "100%", height: "280px" }}>
                  <div style={{ textAlign: "center" }}>
                    {/* Center the image */}
                    <img
                      src={CompanyTypeImg}
                      alt=""
                      style={{ maxWidth: "100%", maxHeight: "100%", height: "150px" }}
                    />
                  </div>
                  <Text
                    className="mt-3"
                    style={{
                      fontSize: "33px",
                      fontWeight: "bold",
                      whiteSpace: "break-spaces",
                      textAlign: "center",
                      display: "block",
                    }}
                  >
                    {t("auth.userType.company")}
                  </Text>
                </Card>
              </Link>
            </Col>
          </Row>
        </Content>
      </Layout>
    </UserTypeFormContainer>
  );
};
export default UserTypeForm;
