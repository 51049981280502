import styled from "styled-components";

const SignUpFormContainer = styled.div`
  padding-bottom: 40px;

  .ant-layout {
    background: transparent;
    margin: 0;
  }

  span.ant-input-password,
  span.ant-input-outlined {
    padding: 0 11px;
  }

  input#password {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .form-item-wrapper .ant-form-item-label {
    display: block !important;
    text-align: left;
  }

  .form-item-wrapper .ant-form-item-control {
    display: block;
  }

  .react-tel-input .form-control {
    border-radius: 6px;
    width: 100%;
    height: 40px;
    color: #000;
    font-size: 14px;
  }

  .ant-card-body {
    padding: 0px 20px;
  }
`;

export default SignUpFormContainer;
