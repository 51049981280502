import { useMutation, useQuery } from "@apollo/client";
import { Button, Col, Form, Input, Layout, Row, Typography } from "antd";
import ApplicationPanelFormContainer from "components/ApplicationPanel/styles";
import { Loading } from "components/Loading";
import { UPDATE_HERO_SECTION_MUTATION } from "graphql/manageLandingPage/mutations";
import { GET_HERO_SECTION_DETAILS } from "graphql/queries";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetHeroSectionResponse, HeroSection, UpdateHeroSectionResponse } from "types/Home";

const { Title } = Typography;
const { Content } = Layout;

const HeroSectionForm = () => {
  const { t } = useTranslation();

  const [image, setImage] = useState<File | undefined>(undefined);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const errorMessageRef = useRef<HTMLDivElement>(null);
  const successMessageRef = useRef<HTMLDivElement>(null);

  const {
    loading: heroSectionDetailsLoading,
    data: heroSectionDetailsData,
    refetch: heroSectionDetailsRefetch,
  } = useQuery<GetHeroSectionResponse>(GET_HERO_SECTION_DETAILS);

  const [updateHeroSectionMutation] = useMutation(UPDATE_HERO_SECTION_MUTATION, {
    onCompleted: (response: UpdateHeroSectionResponse) => {
      if ("updateHeroSection" in response && response.updateHeroSection) {
        setErrorMessage(null);
        setSuccessMessage(t("dashboard.savedSuccessfullyMessage"));
        scrollToSuccess();
        setTimeout(() => {
          setSuccessMessage(null);
        }, 5000); // Clear success message after 5 seconds
        heroSectionDetailsRefetch();
      } else if ("errors" in response) {
        setErrorMessage(response.errors[0]);
        setSuccessMessage(null);
        scrollToError();
      }
    },
  });

  const onFinish = (values: HeroSection) => {
    updateHeroSectionMutation({
      variables: { ...values, image },
    });
  };

  // @ts-expect-error because we don't have they type ValidateErrorEntity exported from antd
  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line no-console
    console.log("Failed:", errorInfo);
  };

  const scrollToError = () => {
    if (errorMessageRef.current) {
      errorMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const scrollToSuccess = () => {
    if (successMessageRef.current) {
      successMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  if (heroSectionDetailsLoading || !heroSectionDetailsData?.heroSectionDetails) return <Loading />;

  return (
    <ApplicationPanelFormContainer>
      <Layout className="p-3 p-md-5 m-0">
        <Content>
          <Row gutter={[24, 0]}>
            <Col xs={24}>
              <Title
                className="mb-5 mt-0"
                style={{
                  fontSize: "45px",
                  whiteSpace: "break-spaces",
                }}
              >
                {t("dashboard.sideNav.heroSectionTab")}
              </Title>
            </Col>
          </Row>

          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            className="row-col"
            scrollToFirstError={true}
            initialValues={heroSectionDetailsData?.heroSectionDetails}
          >
            <div ref={errorMessageRef}></div> {/* Error message ref */}
            {errorMessage && <p className="text-danger english-field">{errorMessage}</p>}
            <div ref={successMessageRef}></div> {/* Success message ref */}
            {successMessage && <p className="text-success">{successMessage}</p>}
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <Form.Item
                  label={t("dashboard.landingPageSettings.heroSection.titleInEnglish")}
                  name="titleEn"
                  rules={[
                    {
                      required: true,
                      message: t("dashboard.applicationPanelForm.requiredFieldMessage"),
                    },
                  ]}
                >
                  <Input.TextArea rows={5} maxLength={255} showCount className="english-field" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <Form.Item
                  label={t("dashboard.landingPageSettings.heroSection.titleInArabic")}
                  name="titleAr"
                  rules={[
                    {
                      required: true,
                      message: t("dashboard.applicationPanelForm.requiredFieldMessage"),
                    },
                  ]}
                >
                  <Input.TextArea rows={5} maxLength={255} showCount className="arabic-field" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <Form.Item
                  label={t("dashboard.landingPageSettings.heroSection.missionInEnglish")}
                  name="missionEn"
                  rules={[
                    {
                      required: true,
                      message: t("dashboard.applicationPanelForm.requiredFieldMessage"),
                    },
                  ]}
                >
                  <Input.TextArea rows={5} maxLength={255} showCount className="english-field" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <Form.Item
                  label={t("dashboard.landingPageSettings.heroSection.missionInArabic")}
                  name="missionAr"
                  rules={[
                    {
                      required: true,
                      message: t("dashboard.applicationPanelForm.requiredFieldMessage"),
                    },
                  ]}
                >
                  <Input.TextArea rows={5} maxLength={255} showCount className="arabic-field" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <Form.Item
                  label={t("dashboard.landingPageSettings.heroSection.visionInEnglish")}
                  name="visionEn"
                  rules={[
                    {
                      required: true,
                      message: t("dashboard.applicationPanelForm.requiredFieldMessage"),
                    },
                  ]}
                >
                  <Input.TextArea rows={5} maxLength={255} showCount className="english-field" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <Form.Item
                  label={t("dashboard.landingPageSettings.heroSection.visionInArabic")}
                  name="visionAr"
                  rules={[
                    {
                      required: true,
                      message: t("dashboard.applicationPanelForm.requiredFieldMessage"),
                    },
                  ]}
                >
                  <Input.TextArea rows={5} maxLength={255} showCount className="arabic-field" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <Form.Item
                  label={
                    <>
                      {t("dashboard.landingPageSettings.heroSection.image")}
                      &nbsp;
                      <a
                        href={heroSectionDetailsData?.heroSectionDetails.image}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ({t("dashboard.landingPageSettings.aboutSection.viewCurrentImage")})
                      </a>
                    </>
                  }
                  name="ourStoryImage"
                  rules={[
                    {
                      message: t("auth.signUp.requiredFieldMessage"),
                    },
                  ]}
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                      if (event.target.files && event.target.files.length > 0)
                        setImage(event.target.files?.[0]);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                    {t("dashboard.applicationPanelForm.saveBtn")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Content>
      </Layout>
    </ApplicationPanelFormContainer>
  );
};

export default HeroSectionForm;
