import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { Button, Table, Tag, Popconfirm, Dropdown } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import type { TableProps, PopconfirmProps } from "antd";

import { DateTime } from "luxon";

import moment from "moment";

import { DATE_FORMAT } from "constants/datetime";

import {
  getRegistrationPaymentStatusColor,
  getRegistrationStatusColor,
} from "helpers/registration";
import { RegistrationListResponse, RegistrationStatus, PaymentStatus } from "types/Registration";

import {
  UPDATE_REGISTRATION_STATUS_MUTATION,
  UPDATE_PAYMENT_STATUS_MUTATION,
} from "../../../graphql/mutations";
import { useMutation } from "@apollo/client";

import AssignToCompanyModal from "./AssignToCompanyModal";
import RegistrationResponseModal from "./RegistrationResponseModal";

type ColumnsType<T extends object> = TableProps<T>["columns"];

type RegistrationsTableProps = TableProps & {
  data: RegistrationListResponse[];
  loading: boolean;
  refetchData: () => void;
};

const RegistrationsTable = ({ data, loading, refetchData }: RegistrationsTableProps) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [isRegistrationResponseModalOpen, setRegistrationResponseModalOpen] = useState(false);
  const [isAssignToCompanyModalOpen, setAssignToCompanyModalOpen] = useState(false);

  const [gender, setGender] = useState<string | null>(null);
  const [registrationID, setRegistrationID] = useState<number | null>(null);
  const [registrationObj, setRegistrationObj] = useState<RegistrationListResponse | null>(null);

  const showRegistrationResponseModal = (record: RegistrationListResponse) => {
    setRegistrationResponseModalOpen(true);
    setRegistrationID(record.id);
    setRegistrationObj(record);
  };

  const showAssignToCompanyModal = (registrationID: number, gender: string) => {
    setAssignToCompanyModalOpen(true);
    setGender(gender);
    setRegistrationID(registrationID);
  };

  const [updateRegistrationMutation] = useMutation(UPDATE_REGISTRATION_STATUS_MUTATION, {
    onCompleted: () => {
      refetchData();
    },
  });

  const [updatePaymentMutation] = useMutation(UPDATE_PAYMENT_STATUS_MUTATION, {
    onCompleted: () => {
      refetchData();
    },
  });

  const getMenuItems = (record: RegistrationListResponse) => {
    return [
      {
        key: "more-details",
        label: (
          <StyledMenuItem type="text" onClick={() => showRegistrationResponseModal(record)}>
            {t("dashboard.viewResponseBtn")}
          </StyledMenuItem>
        ),
      },
      {
        key: "assign-to-company",
        label: (
          <StyledMenuItem
            type="text"
            disabled={
              record.formType === "Seasonal Form" || record.status !== RegistrationStatus.Approved
            }
            onClick={() => showAssignToCompanyModal(record.id, record.participantProfile.gender)}
          >
            {t("dashboard.assignToCompanyBtn")}
          </StyledMenuItem>
        ),
      },
    ];
  };

  const getManagePaymentStatusItems = (record: RegistrationListResponse) => {
    const items = [];
    if (record.paymentStatus !== PaymentStatus.Paid)
      items.push({
        key: "paid",
        label: (
          <Popconfirm
            title={t("dashboard.paymentCompletedTitle")}
            description={t("dashboard.paymentCompletedDescription")}
            onConfirm={() => confirmPaymentStatus(record.id, "PAID")}
            onCancel={cancel}
            okText={t("dashboard.yes")}
            cancelText={t("dashboard.no")}
          >
            <Button type="primary" size="small" className="px-4" style={{ borderRadius: "50px" }}>
              {t("dashboard.paid")}
            </Button>
          </Popconfirm>
        ),
      });

    if (record.paymentStatus !== PaymentStatus.Failed)
      items.push({
        key: "failed",
        label: (
          <Popconfirm
            title={t("dashboard.paymentFailedTitle")}
            description={t("dashboard.paymentFailedDescription")}
            onConfirm={() => confirmPaymentStatus(record.id, "FAILED")}
            onCancel={cancel}
            okText={t("dashboard.yes")}
            cancelText={t("dashboard.no")}
          >
            <Button
              type="default"
              danger
              size="small"
              className="px-4"
              style={{ borderRadius: "50px" }}
            >
              {t("dashboard.failed")}
            </Button>
          </Popconfirm>
        ),
      });

    return items;
  };

  const getManageRegistrationStatusItems = (record: RegistrationListResponse) => {
    const items = [];
    if (record.status !== RegistrationStatus.Approved)
      items.push({
        key: "approved",
        label: (
          <Popconfirm
            title={t("dashboard.approveRegistrationTitle")}
            description={t("dashboard.approveRegistrationDescription")}
            onConfirm={() => confirmRegistrationStatus(record.id, "APPROVED")}
            onCancel={cancel}
            okText={t("dashboard.yes")}
            cancelText={t("dashboard.no")}
          >
            <Button type="primary" size="small" className="px-4" style={{ borderRadius: "50px" }}>
              {t("dashboard.approve")}
            </Button>
          </Popconfirm>
        ),
      });

    if (record.status !== RegistrationStatus.Rejected)
      items.push({
        key: "rejected",
        label: (
          <Popconfirm
            title={t("dashboard.rejectRegistrationTitle")}
            description={t("dashboard.rejectRegistrationDescription")}
            onConfirm={() => confirmRegistrationStatus(record.id, "REJECTED")}
            onCancel={cancel}
            okText={t("dashboard.yes")}
            cancelText={t("dashboard.no")}
          >
            <Button
              type="default"
              danger
              size="small"
              className="px-4"
              style={{ borderRadius: "50px" }}
            >
              {t("dashboard.reject")}
            </Button>
          </Popconfirm>
        ),
      });

    if (record.status !== RegistrationStatus.Withdraw)
      items.push({
        key: "withdraw",
        label: (
          <Popconfirm
            title={t("dashboard.withdrawRegistrationTitle")}
            description={t("dashboard.withdrawRegistrationDescription")}
            onConfirm={() => confirmRegistrationStatus(record.id, "WITHDRAW")}
            onCancel={cancel}
            okText={t("dashboard.yes")}
            cancelText={t("dashboard.no")}
          >
            <Button
              type="default"
              size="small"
              className="px-4"
              style={{ borderRadius: "50px", borderColor: "orange", color: "orange" }}
            >
              {t("dashboard.withdrawRegistration")}
            </Button>
          </Popconfirm>
        ),
      });

    return items;
  };

  const confirmRegistrationStatus = (registrationID: number, status: string) => {
    updateRegistrationMutation({
      variables: { registrationId: registrationID, status },
    });
  };

  const confirmPaymentStatus = (registrationID: number, paymentStatus: string) => {
    updatePaymentMutation({
      variables: { registrationId: registrationID, paymentStatus },
    });
  };

  const cancel: PopconfirmProps["onCancel"] = () => {
    // You can handle cancel logic here if needed
    // For example, you might not need to do anything on cancel
  };

  // Extract unique company names from the data
  const uniqueCompanyNames = Array.from(
    new Set(
      data.map(
        (item) =>
          item.assignedJobOpportunity?.companyRequest?.[
            `companyName${currentLanguage === "en" ? "En" : "Ar"}`
          ],
      ),
    ),
  )
    .filter((name) => !!name)
    .sort((a, b) => a.localeCompare(b));

  const uniqueJobTitles = Array.from(
    new Set(data.map((item) => item.assignedJobOpportunity?.jobTitle)),
  )
    .filter((title) => !!title)
    .sort((a, b) => a.localeCompare(b));

  const columns: ColumnsType<RegistrationListResponse> = [
    {
      title: t("dashboard.participantID"),
      key: "participantProfile.participantId",
      sorter: (a, b) =>
        a.participantProfile.participantId.localeCompare(b.participantProfile.participantId),
      render: (obj) => (
        <span className="english-field">{obj.participantProfile.participantId}</span>
      ),
    },
    {
      title: t("dashboard.participantName"),
      dataIndex: "participantProfile",
      key: "participantProfile.fullName",
      sorter: (a, b) =>
        a.participantProfile.fullNameEn.length - b.participantProfile.fullNameEn.length,
      render: (participantProfile: RegistrationListResponse["participantProfile"]) =>
        currentLanguage === "en" ? participantProfile.fullNameEn : participantProfile.fullNameAr,
    },
    {
      title: t("dashboard.mobileNumber"),
      dataIndex: "participantProfile",
      key: "mobileNumber",
      render: (participantProfile: RegistrationListResponse["participantProfile"]) => (
        // replace - from the phone
        <span dir="ltr">{`+${participantProfile.mobileNumber.replace("-", " ")}`} </span>
      ),
    },
    {
      title: t("dashboard.email"),
      dataIndex: "participantProfile",
      key: "email",
      sorter: (a, b) =>
        a.participantProfile.user.email.length - b.participantProfile.user.email.length,
      render: (participantProfile: RegistrationListResponse["participantProfile"]) => (
        <span className="english-field">{participantProfile.user.email}</span>
      ),
    },
    {
      title: t("dashboard.gender"),
      dataIndex: `participantProfile`,
      key: "gender",
      filters: [
        {
          text: t("dashboard.genderOptions.Male"),
          value: "Male",
        },
        {
          text: t("dashboard.genderOptions.Female"),
          value: "Female",
        },
      ],
      onFilter: (value, record) => record.participantProfile.gender.indexOf(value as string) === 0,
      render: (participantProfile: RegistrationListResponse["participantProfile"]) =>
        t(`dashboard.genderOptions.${participantProfile.gender}`),
    },
    {
      title: t("dashboard.age"),
      dataIndex: `participantProfile`,
      key: "age",
      sorter: (a, b) => a.participantProfile.age - b.participantProfile.age,
      render: (participantProfile: RegistrationListResponse["participantProfile"]) =>
        participantProfile.age,
    },
    {
      title: t("dashboard.dateOfBirth"),
      dataIndex: "participantProfile",
      key: "dateOfBirth",
      render: (participantProfile: RegistrationListResponse["participantProfile"]) =>
        DateTime.fromISO(participantProfile.dateOfBirth).toFormat(DATE_FORMAT),
    },
    {
      title: t("dashboard.registrationDate"),
      key: "createdAt",
      dataIndex: "createdAt",
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (createdAt) => moment(createdAt).format("DD-MM-YYYY"),
    },
    {
      title: t("dashboard.paymentStatus"),
      key: "paymentStatus",
      dataIndex: "paymentStatus",
      filters: [
        {
          text: t("dashboard.pending"),
          value: "PENDING",
        },
        {
          text: t("dashboard.paid"),
          value: "PAID",
        },
        {
          text: t("dashboard.failed"),
          value: "FAILED",
        },
      ],
      onFilter: (value, record) => record.paymentStatus.indexOf(value as string) === 0,
      render: (paymentStatus: RegistrationListResponse["paymentStatus"]) => (
        <span>
          <Tag color={getRegistrationPaymentStatusColor(paymentStatus)} key={paymentStatus}>
            {t(`dashboard.${paymentStatus.toLowerCase()}`).toUpperCase()}
          </Tag>
        </span>
      ),
    },
    {
      title: t("dashboard.status"),
      key: "status",
      dataIndex: "status",
      filters: [
        {
          text: t("dashboard.pending"),
          value: "PENDING",
        },
        {
          text: t("dashboard.approved"),
          value: "APPROVED",
        },
        {
          text: t("dashboard.rejected"),
          value: "REJECTED",
        },
        {
          text: t("dashboard.withdraw"),
          value: "WITHDRAW",
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value as string) === 0,
      render: (status: RegistrationListResponse["status"]) => (
        <span>
          <Tag color={getRegistrationStatusColor(status)} key={status}>
            {t(`dashboard.${status.toLowerCase()}`).toUpperCase()}
          </Tag>
        </span>
      ),
    },
    {
      title: t("dashboard.assignedCompanyName"),
      dataIndex: "assignedJobOpportunity",
      key: "assignedCompanyName",
      filters: [
        { text: "-", value: "-" },
        ...uniqueCompanyNames.map((name) => ({
          text: name,
          value: name,
        })),
      ],
      onFilter: (value, record) => {
        const companyName =
          record.assignedJobOpportunity?.companyRequest?.[
            `companyName${currentLanguage === "en" ? "En" : "Ar"}`
          ];
        return value === "-" ? !companyName : companyName === value;
      },
      render: (assignedJobOpportunity: RegistrationListResponse["assignedJobOpportunity"]) =>
        assignedJobOpportunity?.companyRequest?.[
          `companyName${currentLanguage === "en" ? "En" : "Ar"}`
        ] || "-",
    },
    {
      title: t("dashboard.assignedJobTitle"),
      dataIndex: "assignedJobOpportunity",
      key: "jobTitle",
      filters: [
        { text: "-", value: "-" },
        ...uniqueJobTitles.map((title) => ({ text: title, value: title })),
      ],
      onFilter: (value, record) => {
        const jobTitle = record.assignedJobOpportunity?.jobTitle;
        return value === "-" ? !jobTitle : jobTitle === value;
      },
      render: (assignedJobOpportunity: RegistrationListResponse["assignedJobOpportunity"]) =>
        assignedJobOpportunity?.jobTitle || "-",
    },
    {
      title: t("dashboard.manageRegistration"),
      key: "manageRegistration",
      render: (record: RegistrationListResponse) => {
        const items = getManageRegistrationStatusItems(record);
        return (
          <StyledDropdown menu={{ items }} placement="bottomLeft" trigger={["click"]}>
            <StyledMenuIcon icon={<MoreOutlined />}></StyledMenuIcon>
          </StyledDropdown>
        );
      },
    },
    {
      title: t("dashboard.managePayment"),
      key: "managePayment",
      render: (record: RegistrationListResponse) => {
        const items = getManagePaymentStatusItems(record);
        return (
          <StyledDropdown menu={{ items }} placement="bottomLeft" trigger={["click"]}>
            <StyledMenuIcon icon={<MoreOutlined />}></StyledMenuIcon>
          </StyledDropdown>
        );
      },
    },
    {
      title: t("dashboard.action"),
      key: "action",
      render: (record: RegistrationListResponse) => {
        const items = getMenuItems(record);
        return (
          <StyledDropdown menu={{ items }} placement="bottomLeft" trigger={["click"]}>
            <StyledMenuIcon icon={<MoreOutlined />}></StyledMenuIcon>
          </StyledDropdown>
        );
      },
    },
  ];

  return (
    <div style={{ overflowX: "clip" }}>
      <Table
        columns={columns}
        pagination={{ position: ["bottomRight"] }}
        dataSource={data.map((item) => ({ ...item, key: item.id }))}
        scroll={{ x: true }}
        loading={loading}
      />

      {registrationID && registrationObj && (
        <RegistrationResponseModal
          isModalOpen={isRegistrationResponseModalOpen}
          handleOk={() => setRegistrationResponseModalOpen(false)}
          handleCancel={() => setRegistrationResponseModalOpen(false)}
          registrationID={registrationID}
          registrationObj={registrationObj}
        />
      )}

      {registrationID && gender && (
        <AssignToCompanyModal
          isModalOpen={isAssignToCompanyModalOpen}
          handleOk={() => setAssignToCompanyModalOpen(false)}
          handleCancel={() => setAssignToCompanyModalOpen(false)}
          registrationID={registrationID}
          gender={gender}
          refetchData={refetchData}
        />
      )}
    </div>
  );
};

export default RegistrationsTable;

const StyledDropdown = styled(Dropdown)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px !important;
  height: 40px;
  border: none;
  box-shadow: none;

  svg {
    margin-right: 0;
  }
`;

const StyledMenuItem = styled(Button)`
  box-shadow: none;
  border-radius: none;

  &:hover {
    background: none !important;
  }
`;

const StyledMenuIcon = styled(Button)`
  border-radius: 50px;

  &:hover {
    background: #35b4a7 !important;

    svg {
      color: #fff;
    }
  }
`;
