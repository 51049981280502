import React from "react";
import { useTranslation } from "react-i18next";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { Form, Input, Row, Col, Button, Card, Typography } from "antd";
import JobOpportunitiesForm from "./JobOpportunitiesForm";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const { Title } = Typography;

const LocationsForm = ({ inputFields, setInputFields }) => {
  const { t } = useTranslation();

  const handleFormChange = (index, eventName, eventValue) => {
    const data = [...inputFields];
    data[index][eventName] = eventValue;
    setInputFields(data);
  };

  const addFields = () => {
    const newField = {
      address: "",
      url: "",
      supervisorName: "",
      supervisorNumber: "",
      jobOpportunities: [
        {
          jobTitle: "",
          jobDescription: "",
          malesNumber: "",
          femalesNumber: "",
          startingTime: "",
          endingTime: "",
          workDays: "",
          specialNotes: "",
        },
      ],
    };
    setInputFields([...inputFields, newField]);
  };

  const removeFields = (index) => {
    const data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  return (
    <>
      <Title
        className="mb-3 mt-0"
        style={{
          fontSize: "14px",
          whiteSpace: "break-spaces",
          color: "#35b4a7",
          fontWeight: 700,
        }}
      >
        {t("requestForm.collapseHeader.locationsInfo")}
      </Title>

      <Form.List name="locationsInfoForm" initialValue={inputFields}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <React.Fragment key={field.key}>
                <Card
                  title={t("requestForm.locationsForm.cardTitle") + (index + 1)}
                  extra={
                    fields.length > 1 ? (
                      <DeleteFilled
                        onClick={() => {
                          removeFields(index);
                          remove(field.name);
                        }}
                      />
                    ) : null
                  }
                  className={index === fields.length - 1 ? "mb-4" : "mb-5"}
                >
                  <Row gutter={[8, 8]}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={t("requestForm.locationsForm.locationAddressField")}
                        name={[index, "address"]}
                        rules={[
                          {
                            required: true,
                            message: t("requestForm.requiredFieldMessage"),
                          },
                        ]}
                      >
                        <Input
                          maxLength={100}
                          showCount
                          onChange={(event) =>
                            handleFormChange(index, "address", event.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        label={t("requestForm.locationsForm.locationUrlField")}
                        name={[index, "url"]}
                        rules={[
                          {
                            type: "url",
                            message: t("requestForm.validUrlMessage"),
                          },
                          {
                            required: true,
                            message: t("requestForm.requiredFieldMessage"),
                          },
                        ]}
                      >
                        <Input
                          onChange={(event) => handleFormChange(index, "url", event.target.value)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[8, 8]}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={t("requestForm.locationsForm.supervisorNameField")}
                        name={[index, "supervisorName"]}
                        rules={[
                          {
                            required: true,
                            message: t("requestForm.requiredFieldMessage"),
                          },
                        ]}
                      >
                        <Input
                          maxLength={100}
                          showCount
                          onChange={(event) =>
                            handleFormChange(index, "supervisorName", event.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name={[index, "supervisorNumber"]}
                        label={t("requestForm.locationsForm.supervisorNumberField")}
                        rules={[{ required: true, message: t("requestForm.requiredFieldMessage") }]}
                      >
                        <PhoneInput
                          className="english-field"
                          country="kw"
                          placeholder=""
                          excludeCountries={["il"]}
                          preferredCountries={["kw"]}
                          onChange={(value, data) => {
                            const countryCode = data.dialCode;
                            const countryCodeIndex = value.indexOf(countryCode);
                            const length = countryCode.length;
                            const number =
                              countryCode + "-" + value.slice(countryCodeIndex + length);
                            handleFormChange(index, "supervisorNumber", number);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[8, 8]}>
                    <Col xs={24}>
                      <Form.Item>
                        <JobOpportunitiesForm
                          field={field}
                          locationObjIndex={index}
                          jobOpportunitiesList={inputFields[index].jobOpportunities}
                          setJobOpportunitiesList={handleFormChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>

                {index === fields.length - 1 && (
                  <Col
                    xs={24}
                    className="d-flex justify-content-center align-items-center mb-3 mt-0"
                  >
                    <Button
                      onClick={() => {
                        addFields();
                        add();
                      }}
                      style={{ width: "100%" }}
                      icon={<PlusOutlined />}
                      className="custom-button"
                    >
                      {t("requestForm.locationsForm.addLocationButton")}
                    </Button>
                  </Col>
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </Form.List>
    </>
  );
};

export default LocationsForm;
