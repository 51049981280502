import React from "react";
import { useTranslation } from "react-i18next";

import { Col, Typography } from "antd";

const { Title, Text } = Typography;

const Member = ({ obj }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <Col>
      <img
        src={obj.image}
        alt=""
        style={{
          width: "200px",
          height: "200px",
          borderRadius: "50%",
          marginBottom: "10px",
        }}
      />
      <Title
        level={4}
        style={{
          textAlign: "center",
        }}
      >
        {obj["fullName" + (currentLanguage === "en" ? "En" : "Ar")]}
      </Title>
      <Text
        style={{
          textAlign: "center",
          display: "block",
        }}
      >
        {obj["jobTitle" + (currentLanguage === "en" ? "En" : "Ar")]}
      </Text>
    </Col>
  );
};

export default Member;
