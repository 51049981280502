import { useMutation, useQuery } from "@apollo/client";
import { Button, Col, Form, Layout, Row, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import ApplicationPanelFormContainer from "components/ApplicationPanel/styles";
import { Loading } from "components/Loading";
import { ADD_OR_UPDATE_SPONSORS_MUTATION } from "graphql/manageLandingPage/mutations";
import { GET_SPONSORS_LIST } from "graphql/queries";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import SponsorsFormList from "./SponsorsFormList";

const { Title } = Typography;

const SponsorsForm = () => {
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const errorMessageRef = useRef(null);
  const successMessageRef = useRef(null);

  const [submitting, setSubmitting] = useState(false);

  const {
    loading: sponsorsListLoading,
    data: sponsorsListData,
    refetch: sponsorsListRefetch,
  } = useQuery(GET_SPONSORS_LIST, {
    variables: {},
  });

  const [addOrUpdateSponsorsMutation] = useMutation(ADD_OR_UPDATE_SPONSORS_MUTATION, {
    onCompleted: (data) => {
      setSubmitting(false);
      if (data.addOrUpdateSponsor.success) {
        setErrorMessage(null);
        setSuccessMessage(t("dashboard.savedSuccessfullyMessage"));
        scrollToSuccess();
        setTimeout(() => {
          setSuccessMessage(null);
        }, 5000); // Clear success message after 5 seconds
        sponsorsListRefetch();
      } else {
        setErrorMessage(data.addOrUpdateSponsor.errors[0]);
        setSuccessMessage(null);
        scrollToError();
      }
    },
  });

  const onFinish = () => {
    setSubmitting(true);

    addOrUpdateSponsorsMutation({
      variables: { sponsorsList: inputFields },
    });
  };

  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line no-console
    console.log("Failed:", errorInfo);
  };

  const scrollToError = () => {
    if (errorMessageRef.current) {
      errorMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const scrollToSuccess = () => {
    if (successMessageRef.current) {
      successMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  // wait till the form initial values are calculated, as the form won't re-render.
  const [inputFieldsLoading, setInputFieldsLoading] = useState(true);
  const [inputFields, setInputFields] = useState([]);

  useEffect(() => {
    if (!sponsorsListLoading) {
      setInputFieldsLoading(true);
      const data = sponsorsListData.sponsorsList.map((obj) => {
        return {
          id: obj.id,
          name: obj.name,
          active: obj.active,
        };
      });
      setInputFields(data);
      setInputFieldsLoading(false);
    }
  }, [sponsorsListData, sponsorsListLoading]);

  if (sponsorsListLoading || inputFieldsLoading) return <Loading />;

  return (
    <ApplicationPanelFormContainer>
      <Layout className="p-3 p-md-5 m-0">
        <Content>
          <Row gutter={[24, 0]}>
            <Col xs={24}>
              <Title
                className="mb-5 mt-0"
                style={{
                  fontSize: "45px",
                  whiteSpace: "break-spaces",
                }}
              >
                {t("dashboard.sideNav.sponsorsTab")}
              </Title>
            </Col>
          </Row>

          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="row-col"
            scrollToFirstError={true}
          >
            <div ref={errorMessageRef}></div> {/* Error message ref */}
            {errorMessage && <p className="text-danger english-field">{errorMessage}</p>}
            <div ref={successMessageRef}></div> {/* Success message ref */}
            {successMessage && <p className="text-success">{successMessage}</p>}
            <SponsorsFormList
              inputFields={inputFields}
              setInputFields={setInputFields}
              data={sponsorsListData.sponsorsList}
            />
            <Row gutter={[24, 0]} justify="center">
              <Col xs={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                    loading={submitting}
                    disabled={submitting}
                  >
                    {t("dashboard.applicationPanelForm.saveBtn")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Content>
      </Layout>
    </ApplicationPanelFormContainer>
  );
};

export default SponsorsForm;
