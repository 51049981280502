import React from "react";

import { useQuery } from "@apollo/client";
import { GET_PARTICIPATING_ENTITIES_LIST } from "../../../graphql/queries";

import { Layout, Row } from "antd";

import { Loading } from "../../Loading";

import LogoCarousel from "./LogoCarousel";

import ParticipatingEntitiesContainer from "./styles";

const { Content } = Layout;

const ParticipatingEntities = () => {
  const { loading: participatingEntitiesListLoading, data: participatingEntitiesListData } =
    useQuery(GET_PARTICIPATING_ENTITIES_LIST, {
      variables: { active: true },
    });

  if (participatingEntitiesListLoading) return <Loading />;

  return (
    <ParticipatingEntitiesContainer id="our-partners">
      <Layout
        className="layout-default"
        style={{
          marginTop: "60px",
        }}
      >
        <Content>
          <Row gutter={[24, 40]} justify="space-around">
            <LogoCarousel data={participatingEntitiesListData.participatingEntitiesList} />
          </Row>
        </Content>
      </Layout>
    </ParticipatingEntitiesContainer>
  );
};

export default ParticipatingEntities;
