import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Modal, DatePicker, Row, Col, Button, Form, Radio } from "antd";

const { RangePicker } = DatePicker;

const FilterModal = ({ isModalOpen, handleOk, handleCancel, setFilterQuery }) => {
  const { t } = useTranslation();

  const [startingDate, setStartingDate] = useState(null);
  const [endingDate, setEndingDate] = useState(null);

  const errorMessageRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const onFinish = (values) => {
    const isEmpty = Object.values(values).every((value) => !value);
    if (isEmpty) {
      setErrorMessage(t("dashboard.attendanceTable.filterModal.selectFilterMessage"));
      scrollToError();
    } else {
      setErrorMessage("");
      const data = { ...values, startingDate, endingDate };
      setFilterQuery(JSON.stringify(data));
      handleCancel();
    }
  };

  const clearAllFilters = () => {
    window.location.reload();
  };

  const scrollToError = () => {
    if (errorMessageRef.current) {
      errorMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <Modal
      title={t("dashboard.registrationsTable.filterModal.modalTitle")}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={600}
      footer={null}
    >
      <Form onFinish={onFinish} layout="vertical" className="row-col" scrollToFirstError={true}>
        <div ref={errorMessageRef}></div> {/* Error message ref */}
        {errorMessage && <p className="text-danger">{errorMessage}</p>}
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            <Form.Item
              label={t("dashboard.registrationsTable.filterModal.dateRangeLabel")}
              name="dateRange"
            >
              <RangePicker
                format="YYYY-MM-DD"
                style={{ width: "100%" }}
                className="english-field"
                onChange={(dateObj, dateString) => {
                  // dateString will be in the format "YYYY-MM-DD"
                  setStartingDate(dateString[0]);
                  setEndingDate(dateString[1]);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              label={t("dashboard.applicationPanelForm.formType")}
              name="formType"
              initialValue=""
            >
              <Radio.Group>
                <Radio value="Waed Form">{t("dashboard.applicationPanelForm.waedForm")}</Radio>
                <Radio value="Seasonal Form">
                  {t("dashboard.applicationPanelForm.seasonalForm")}
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} md={12}>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                {t("dashboard.attendanceTable.filterModal.applyFiltersButton")}
              </Button>
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item>
              <Button type="default" style={{ width: "100%" }} onClick={() => clearAllFilters()}>
                {t("dashboard.attendanceTable.filterModal.clearAllFilterButton")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default FilterModal;
