import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { PAYMENT_METHODS_LIST_MUTATION } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";

import { Loading } from "components/Loading";

import PaymentMethodForm from "./PaymentMethodForm";

import { Result } from "antd";

const PaymentMethod = () => {
  const { t } = useTranslation();

  const [paymentMethodsList, setPaymentMethodsList] = useState([]);
  const [paymentMethodsListLoading, setPaymentMethodsListLoading] = useState(true);

  const [errorMessage, setErrorMessage] = useState(null);

  const [fetchPaymentMethodsList] = useMutation(PAYMENT_METHODS_LIST_MUTATION);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await fetchPaymentMethodsList();

      if (!data.paymentMethodsList.success) setErrorMessage(data.paymentMethodsList.errors[0]);
      else {
        const paymentMethodsData = data.paymentMethodsList.paymentMethods;
        setPaymentMethodsList(paymentMethodsData.map((obj) => JSON.parse(obj)));
      }

      setPaymentMethodsListLoading(false);
    };

    fetchData();
  }, []);

  if (paymentMethodsListLoading) return <Loading />;

  if (errorMessage)
    return (
      <Result
        status="error"
        title={t("payment.paymentMethodForm.errorMessageTitle")}
        subTitle={errorMessage}
      />
    );

  return <PaymentMethodForm {...{ paymentMethodsList }} />;
};

export default PaymentMethod;
