import React from "react";

type PageLayoutProps = {
  children: React.ReactNode;
};

const PageLayout = ({ children }: PageLayoutProps) => {
  return <div className="mx-2 mx-md-5 my-5">{children}</div>;
};

export default PageLayout;
