import styled from "styled-components";

const AccountVerificationContainer = styled.div`
  .ant-layout {
    background: transparent;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-layout-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .form-item-wrapper .ant-form-item-label {
    display: block !important;
    text-align: left;
  }

  .form-item-wrapper .ant-form-item-control {
    display: block;
  }

  .ant-card-body {
    padding: 0px 20px;
  }
`;

export default AccountVerificationContainer;
