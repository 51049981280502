import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Modal, Form, Button, Input } from "antd";

import { SEND_WHATSAPP_MESSAGE_MUTATION } from "graphql/mutations";
import { useMutation } from "@apollo/client";

const SendWhatsAppMessageModal = ({ isModalOpen, handleOk, handleCancel }) => {
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const [submitting, setSubmitting] = useState(false);

  const [sendWhatsappMutation] = useMutation(SEND_WHATSAPP_MESSAGE_MUTATION, {
    onCompleted: (data) => {
      setSubmitting(false);
      if (data.sendWhatsapp.success) {
        setErrorMessage(null);
        setSuccessMessage(t("dashboard.sendWhatAppModal.sentSuccessMessage"));
        setTimeout(() => {
          setSuccessMessage(null);
        }, 5000); // Clear success message after 5 seconds
      } else {
        setErrorMessage(data.sendWhatsapp.errors[0]);
        setSuccessMessage(null);
      }
    },
  });

  const onFinish = (values) => {
    setSubmitting(true);

    sendWhatsappMutation({
      variables: {
        templateName: values.templateName,
        to: "whatsapp:+96599432134",
      },
    });
  };

  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line no-console
    console.log("Failed:", errorInfo);
  };

  return (
    <Modal
      title={t("dashboard.sendWhatAppModal.modalTitle")}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={600}
      footer={null}
    >
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="row-col"
        scrollToFirstError={true}
      >
        {errorMessage && <p className="text-danger english-field">{errorMessage}</p>}

        {successMessage && <p className="text-success">{successMessage}</p>}

        <Form.Item
          label={t("dashboard.sendWhatAppModal.templateName")}
          name="templateName"
          rules={[
            {
              required: true,
              message: t("dashboard.assignToCompanyModal.requiredFieldMessage"),
            },
          ]}
          initialValue=""
        >
          <Input maxLength={255} showCount />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            loading={submitting}
            disabled={submitting}
          >
            {t("dashboard.sendWhatAppModal.sendButton")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SendWhatsAppMessageModal;
