import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Col, Typography } from "antd";
import { useState } from "react";
const { Title, Text } = Typography;

type Props = {
  years: string[];
  currentActiveYear: string | null;
  handleChangeActiveYear: (year: string) => void;
};

const YearSwitcher = ({ years, currentActiveYear, handleChangeActiveYear }: Props) => {
  const [selectedYear, setSelectedYear] = useState<string | null>(currentActiveYear);

  const yearOnTheRight =
    (years?.length > 1 && years[years.indexOf(selectedYear || "") + 1]) || null;

  const yearOnTheLeft = (years?.length > 1 && years[years.indexOf(selectedYear || "") - 1]) || null;

  const handleClickYearOnTheLeft = () => {
    if (yearOnTheLeft) {
      setSelectedYear(yearOnTheLeft);
      handleChangeActiveYear(yearOnTheLeft);
    }
  };
  const handleClickYearOnTheRight = () => {
    if (yearOnTheRight) {
      setSelectedYear(yearOnTheRight);
      handleChangeActiveYear(yearOnTheRight);
    }
  };
  return (
    <>
      <Col
        xs={8}
        style={{
          textAlign: "start",
        }}
      >
        {yearOnTheLeft && (
          <Text
            style={{
              fontSize: "20px",
              color: "rgb(45, 165, 149)",
              cursor: "pointer",
            }}
            onClick={handleClickYearOnTheLeft}
          >
            <ArrowLeftOutlined />
          </Text>
        )}
      </Col>

      <Col
        xs={8}
        style={{
          textAlign: "center",
        }}
      >
        <Title
          className="mb-4 mt-0"
          style={{
            fontSize: "24px",
            color: "rgb(45, 165, 149)",
          }}
        >
          {selectedYear}
        </Title>
      </Col>

      <Col
        xs={8}
        style={{
          textAlign: "end",
        }}
      >
        {yearOnTheRight && (
          <Text
            style={{
              fontSize: "20px",
              color: "rgb(45, 165, 149)",
              textAlign: "end",
              cursor: "pointer",
            }}
            onClick={handleClickYearOnTheRight}
          >
            <ArrowRightOutlined />
          </Text>
        )}
      </Col>
    </>
  );
};

export default YearSwitcher;
