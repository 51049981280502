import React from "react";
import { useTranslation } from "react-i18next";

import { Card, Col, Form, Input, Row, Select, Checkbox } from "antd";

const { Option } = Select;

const SocialMediaFormList = ({ inputFields, setInputFields }) => {
  const { t } = useTranslation();

  const handleFormChange = (index, eventName, eventValue) => {
    const data = [...inputFields];
    data[index][eventName] = eventValue;
    setInputFields(data);
  };

  return (
    <Form.List name="socialMediaForm" initialValue={inputFields}>
      {(fields) => {
        return fields.map((field, index) => (
          <React.Fragment key={field.key}>
            <Card
              title={t("dashboard.landingPageSettings.socialMediaSection.cardTitle") + (index + 1)}
              className={index === fields.length - 1 ? "mb-4" : "mb-5"}
            >
              <Row gutter={[8, 8]}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t("dashboard.landingPageSettings.socialMediaSection.type")}
                    name={[index, "type"]}
                    rules={[
                      {
                        required: true,
                        message: t("requestForm.requiredFieldMessage"),
                      },
                    ]}
                  >
                    <Select>
                      <Option key="Instagram" value="Instagram">
                        {t("dashboard.landingPageSettings.socialMediaSection.instagram")}
                      </Option>
                      <Option key="Linkedin" value="Linkedin">
                        {t("dashboard.landingPageSettings.socialMediaSection.linkedin")}
                      </Option>
                      <Option key="Twitter" value="Twitter">
                        {t("dashboard.landingPageSettings.socialMediaSection.twitter")}
                      </Option>
                      <Option key="Facebook" value="Facebook">
                        {t("dashboard.landingPageSettings.socialMediaSection.facebook")}
                      </Option>
                      <Option key="Tiktok" value="Tiktok">
                        {t("dashboard.landingPageSettings.socialMediaSection.tiktok")}
                      </Option>
                      <Option key="Snapchat" value="Snapchat">
                        {t("dashboard.landingPageSettings.socialMediaSection.snapchat")}
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t("dashboard.landingPageSettings.socialMediaSection.url")}
                    name={[index, "url"]}
                    rules={[
                      {
                        required: inputFields[index].active,
                        message: t("requestForm.requiredFieldMessage"),
                      },
                    ]}
                  >
                    <Input
                      maxLength={200}
                      showCount
                      onChange={(event) => handleFormChange(index, "url", event.target.value)}
                      className="english-field"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[8, 8]}>
                <Col xs={24}>
                  <Form.Item
                    label={t("dashboard.landingPageSettings.socialMediaSection.active")}
                    name={[index, "active"]}
                    rules={[
                      {
                        required: true,
                        message: t("requestForm.requiredFieldMessage"),
                      },
                    ]}
                  >
                    <Checkbox
                      checked={inputFields[index].active}
                      onChange={(event) => handleFormChange(index, "active", event.target.checked)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </React.Fragment>
        ));
      }}
    </Form.List>
  );
};

export default SocialMediaFormList;
