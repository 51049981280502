import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { Row, Col, Result, Alert } from "antd";

import { COMPANY_PROFILE_INFORMATION } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";

import CompanyProfileContainer from "./styles";

import ProfileInfoCard from "./ProfileInfoCard";

import { Loading } from "components/Loading";

const CompanyProfile = () => {
  const { t } = useTranslation();

  const { userID } = useParams();

  const [showAlert, setShowAlert] = useState(false);

  const {
    loading: companyProfileDataLoading,
    error: companyProfileDataError,
    data: companyProfileData,
    refetch: companyProfileDataRefetch,
  } = useQuery(COMPANY_PROFILE_INFORMATION, {
    variables: { userId: parseInt(userID) },
  });

  useEffect(() => {
    const emailVerifiedSuccessfullyMessage = localStorage.getItem(
      "emailVerifiedSuccessfullyMessage",
    );
    if (emailVerifiedSuccessfullyMessage === "true") {
      setShowAlert(true);

      localStorage.removeItem("emailVerifiedSuccessfullyMessage");

      // Automatically hide the alert after 5 seconds
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
  }, []);

  if (companyProfileDataLoading) return <Loading />;

  if (companyProfileDataError)
    return (
      <CompanyProfileContainer>
        <Result
          status="error"
          title={t("auth.companyProfile.errorFetchingDataMessage")}
          subTitle={companyProfileDataError.message}
        />
      </CompanyProfileContainer>
    );

  return (
    <CompanyProfileContainer>
      {showAlert && (
        <Row gutter={[24, 40]} className="mb-3">
          <Col xs={24}>
            <Alert
              message={t("auth.accountVerification.emailVerifiedSuccessfullyMessage")}
              type="success"
              closable
              onClose={() => setShowAlert(false)} // Close the alert when clicked
            />
          </Col>
        </Row>
      )}

      <Row gutter={[24, 40]} className="mb-5">
        <Col xs={24}>
          <ProfileInfoCard
            profileInfo={companyProfileData.companyProfile}
            userID={userID}
            companyProfileDataRefetch={companyProfileDataRefetch}
          />
        </Col>
      </Row>
    </CompanyProfileContainer>
  );
};

export default CompanyProfile;
