import styled from "styled-components";

const NavbarStyles = styled.div`
  .navbar {
    padding-bottom: 1px;
    background-color: white;
    position: sticky;
    top: 0;
    margin-bottom: 20px;
    z-index: 99990;
    display: flex;
    align-items: center; /* Vertically center the items */
  }

  .nav-header {
    background-color: white;
    border-bottom: 0;
    display: flex;
    justify-content: space-between; /* Space out the logo, leftMenu, and rightMenu */
    align-items: center;
    width: 100%; /* Ensure the header takes full width */
  }

  .signInBtn {
    box-shadow: none;
  }

  .signUpBtn {
    padding-left: 25px;
    padding-right: 25px;
  }

  .ant-layout-rtl {
    direction: ltr;
  }

  .logo {
    flex: 0 0 auto; /* The logo should not grow or shrink, and its size should be determined by its content */
  }

  .logo a {
    display: inline-block;
    font-size: 20px;
    text-transform: capitalize;
  }

  .leftMenu {
    flex: 1; /* Let leftMenu take up remaining space */
    display: flex;
    justify-content: center; /* Center the items horizontally */
  }

  .leftMenu .ant-menu-horizontal {
    width: 100%; /* Set the width to 100% of its container */
    display: flex; /* Use flexbox */
    justify-content: center; /* Center the items horizontally */
  }

  .rightMenu {
    flex: 0 0 auto; /* The rightMenu should not grow or shrink, and its size should be determined by its content */
  }

  .ant-menu-horizontal,
  .ant-menu-item::after,
  .ant-menu-submenu::after {
    border: none !important;
    direction: rtl;
  }

  .menuButton {
    float: right;
    height: 32px;
    padding: 6px;
    display: none;
    background: none;
  }

  .ant-drawer-body {
    padding: 0;
  }

  .ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
  .ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
    display: inline-block;
    width: 100%;
  }

  .ant-drawer-body .ant-menu-horizontal {
    border-bottom: none;
  }

  .ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
    border-bottom-color: transparent;
  }

  span.username {
    display: none;
    margin-left: 10px;
  }

  @media (max-width: 767px) {
    .menuButton {
      display: block;
    }

    .leftMenu,
    .rightMenu {
      display: none;
    }

    .logo a {
      margin-left: -10px;
    }

    .ant-drawer-title > .brand-font {
      margin-bottom: 0;
    }

    .username {
      display: inline-block;
    }
  }

  .ant-menu-title-content {
    font-weight: bold;
  }

  .ant-btn svg {
    margin-right: 0;
  }

  .ant-btn span {
    display: flex;
  }
`;

export default NavbarStyles;
