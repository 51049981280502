import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Form, Input, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const OurStoryStatisticalItemsFormList = ({ inputFields, setInputFields }) => {
  const { t } = useTranslation();

  const handleFormChange = (index, eventName, eventValue) => {
    const data = [...inputFields];
    data[index][eventName] = eventValue;
    setInputFields(data);
  };

  const addFields = () => {
    const newField = {
      value: "",
      titleEn: "",
      titleAr: "",
      active: false,
    };
    setInputFields([...inputFields, newField]);
  };

  const removeFields = async (index) => {
    const data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  return (
    <Form.List name="ourValuesForm" initialValue={inputFields}>
      {(fields, { add, remove }) => {
        return fields.map((field, index) => (
          <React.Fragment key={field.key}>
            <Card
              title={
                t("dashboard.landingPageSettings.ourStatisticsSection.cardTitle") + (index + 1)
              }
              extra={
                "id" in inputFields[index] ? null : (
                  <DeleteFilled
                    onClick={() => {
                      removeFields(index);
                      remove(field.name);
                    }}
                  />
                )
              }
              className={index === fields.length - 1 ? "mb-4" : "mb-5"}
            >
              <Row gutter={[8, 8]}>
                <Col xs={24} lg={24}>
                  <Form.Item
                    label={t("dashboard.landingPageSettings.ourStatisticsSection.titleInEnglish")}
                    name={[index, "titleEn"]}
                    rules={[
                      {
                        required: true,
                        message: t("requestForm.requiredFieldMessage"),
                      },
                    ]}
                  >
                    <Input.TextArea
                      rows={5}
                      maxLength={255}
                      showCount
                      className="english-field"
                      onChange={(event) => handleFormChange(index, "titleEn", event.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} lg={24}>
                  <Form.Item
                    label={t("dashboard.landingPageSettings.ourStatisticsSection.titleInArabic")}
                    name={[index, "titleAr"]}
                    rules={[
                      {
                        required: true,
                        message: t("requestForm.requiredFieldMessage"),
                      },
                    ]}
                  >
                    <Input.TextArea
                      rows={5}
                      maxLength={255}
                      showCount
                      className="arabic-field"
                      onChange={(event) => handleFormChange(index, "titleAr", event.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[8, 8]}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t("dashboard.landingPageSettings.ourStatisticsSection.value")}
                    name={[index, "value"]}
                    rules={[
                      {
                        required: true,
                        message: t("requestForm.requiredFieldMessage"),
                      },
                    ]}
                  >
                    <Input
                      maxLength={50}
                      showCount
                      onChange={(event) => handleFormChange(index, "value", event.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[8, 8]}>
                <Col xs={24}>
                  <Form.Item
                    label={t("dashboard.landingPageSettings.ourStatisticsSection.active")}
                    name={[index, "active"]}
                  >
                    <Checkbox
                      checked={inputFields[index].active}
                      onChange={(event) => handleFormChange(index, "active", event.target.checked)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            {index === fields.length - 1 && (
              <Row gutter={[8, 8]} justify="center">
                <Col xs={24} className="mb-4">
                  <Button
                    onClick={() => {
                      addFields();
                      add();
                    }}
                    style={{ width: "100%" }}
                    icon={<PlusOutlined />}
                  />
                </Col>
              </Row>
            )}
          </React.Fragment>
        ));
      }}
    </Form.List>
  );
};
export default OurStoryStatisticalItemsFormList;
