import { PaymentStatus, RegistrationStatus } from "types/Registration";

export const getRegistrationStatusColor = (status: string) => {
  if (status === RegistrationStatus.Approved) {
    return "green";
  } else if (status === RegistrationStatus.Rejected) {
    return "volcano";
  } else if (status === RegistrationStatus.Pending) {
    return "gold";
  } else if (status === RegistrationStatus.Withdraw) {
    return "purple";
  }
};

export const getRegistrationPaymentStatusColor = (status: string) => {
  if (status === PaymentStatus.Paid) {
    return "green";
  } else if (status === PaymentStatus.Failed) {
    return "volcano";
  } else if (status === PaymentStatus.Pending) {
    return "gold";
  }
};
