import React from "react";
import { useTranslation } from "react-i18next";

import { Form, Input, Row, Col, Card, Typography } from "antd";

const { Title } = Typography;

const ProgramInfoForm = ({ setCertificateCopy }) => {
  const { t } = useTranslation();

  return (
    <>
      <Title
        className="mb-3 mt-0"
        style={{
          fontSize: "14px",
          whiteSpace: "break-spaces",
          color: "#35b4a7",
          fontWeight: 700,
        }}
      >
        {t("requestForm.collapseHeader.programInfo")}
      </Title>

      <Card className="mb-3 mt-0">
        <Row gutter={[24, 0]}>
          <Col xs={24} lg={12}>
            <Form.Item
              label={t("requestForm.programInfoForm.trainingTypeField")}
              name="trainingType"
              rules={[
                {
                  required: true,
                  message: t("requestForm.requiredFieldMessage"),
                },
              ]}
            >
              <Input.TextArea rows={5} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label={t("requestForm.programInfoForm.specialRequirementsField")}
              name="specialRequirements"
              rules={[
                {
                  required: true,
                  message: t("requestForm.requiredFieldMessage"),
                },
              ]}
            >
              <Input.TextArea rows={5} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            <Form.Item
              label={
                <ul
                  className="p-0"
                  style={{
                    listStyleType: "none",
                  }}
                >
                  <li>{t("requestForm.programInfoForm.certificateCopyField")}</li>
                  <li className="px-3">
                    - {t("requestForm.programInfoForm.includeWaedLogo")}
                    <a
                      href="https://admin.waedkw.org/media/logo.png"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      &nbsp;({t("requestForm.programInfoForm.getWaedLogo")})
                    </a>
                  </li>
                  <li className="px-3">- {t("requestForm.programInfoForm.certificateUpload")}</li>
                </ul>
              }
              name="certificateCopy"
            >
              <input
                type="file"
                accept="image/*"
                onChange={(event) => {
                  setCertificateCopy(event.target.files[0]);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </>
  );
};
export default ProgramInfoForm;
