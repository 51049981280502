import { useState } from "react";
import { Form, Input, Button, Layout, Typography, Card, Row, Col } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { RESET_PASSWORD_MUTATION } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import PasswordResetFormContainer from "./styles";

const { Content } = Layout;
const { Title } = Typography;

const PasswordResetForm = () => {
  const { uid, token } = useParams();

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [resetPasswordMutation] = useMutation(RESET_PASSWORD_MUTATION, {
    onCompleted: (data) => {
      if (data.resetPassword.success) {
        setErrorMessage(null);
        setSubmitting(false);
        localStorage.setItem("passwordResetSuccessfullyMessage", "true");
        navigate("/sign-in");
      } else {
        setErrorMessage(data.resetPassword.errors[0]);
        setSubmitting(false);
      }
    },
  });

  const onFinish = async (values) => {
    setSubmitting(true);
    resetPasswordMutation({
      variables: { ...values, uid, token },
    });
  };

  return (
    <PasswordResetFormContainer currentLanguage={currentLanguage}>
      <Layout className="layout-default ant-layout layout-sign-up">
        <Content className="pt-0">
          <Title
            className="mb-3 mt-0"
            style={{
              fontSize: "45px",
              whiteSpace: "break-spaces",
              textAlign: "center",
            }}
          >
            {t("auth.forgotPassword.passwordResetForm.title")}
          </Title>

          <Card className="card-signup header-solid h-full ant-card pt-0" bordered="false">
            <Form onFinish={onFinish} scrollToFirstError={true}>
              {errorMessage && <p className="text-danger english-field">{errorMessage}</p>}

              <Row gutter={[24, 0]}>
                <Col xs={24}>
                  <Form.Item
                    label={t("auth.forgotPassword.passwordResetForm.newPasswordField")}
                    name="newPassword"
                    rules={[
                      {
                        required: true,
                        message: t("auth.signUp.requiredFieldMessage"),
                      },
                    ]}
                  >
                    <Input.Password
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      className="english-field"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[24, 0]}>
                <Col xs={24}>
                  <Form.Item
                    label={t("auth.forgotPassword.passwordResetForm.confirmNewPasswordField")}
                    name="confirmNewPassword"
                    rules={[
                      {
                        required: true,
                        message: t("auth.signUp.requiredFieldMessage"),
                      },
                    ]}
                  >
                    <Input.Password
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      className="english-field"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[24, 0]}>
                <Col xs={24}>
                  <Form.Item className="text-center">
                    <Button
                      style={{ width: "60%" }}
                      type="primary"
                      htmlType="submit"
                      loading={submitting}
                      disabled={submitting}
                    >
                      {t("auth.forgotPassword.passwordResetForm.resetPasswordBtn")}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Content>
      </Layout>
    </PasswordResetFormContainer>
  );
};

export default PasswordResetForm;
