import "react-phone-input-2/lib/bootstrap.css";
import { jwtDecode } from "jwt-decode";
import { EyeInvisibleOutlined, EyeTwoTone, InfoCircleFilled } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select, Space, Divider } from "antd";
import { signInWithGoogle } from "configs/firebase";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import countryList from "react-select-country-list";
import GoogleLogo from "../../assets/images/Google__G__Logo.png";
import useAuth from "../../auth";
import {
  PARTICIPANT_SIGNUP_MUTATION,
  SIGNUP_WITH_GOOGLE_MUTATION,
  CHECK_IF_EMAIL_EXISTS_MUTATION,
} from "../../graphql/mutations";

const { Option } = Select;

const ParticipantForm = () => {
  const { t } = useTranslation();

  const [mobileNumber, setMobileNumber] = useState(null);
  const [guardianMobileNumber, setGuardianMobileNumber] = useState(null);
  const [emergencyContactNumber, setEmergencyContactNumber] = useState(null);

  const [languages, setLanguages] = useState([]);
  const [chronicDiseasesYesNo, setChronicDiseasesYesNoField] = useState(null);

  const [dateOfBirth, setDateOfBirth] = useState(null);

  const [errorMessage, setErrorMessage] = useState(null);
  const errorMessageRef = useRef(null);

  const [emailExistsMessage, setEmailExistsMessage] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  const { signIn } = useAuth();

  const [checkIfEmailExistsMutation] = useMutation(CHECK_IF_EMAIL_EXISTS_MUTATION, {
    onCompleted: async (data) => {
      if (data.checkIfEmailExists.success) setEmailExistsMessage(data.checkIfEmailExists.exists);
      else setEmailExistsMessage(false);
    },
  });

  const [signupMutation] = useMutation(PARTICIPANT_SIGNUP_MUTATION, {
    onCompleted: async (data) => {
      if (data.participantRegister.success) {
        setErrorMessage(null);
        const token = data.participantRegister.token;
        await signIn({ token });
        navigate("/account/verify");
      } else {
        setErrorMessage(data.participantRegister.errors[0]);
        scrollToError();
        setSubmitting(false);
      }
    },
  });

  const [signupWithGoogleMutation] = useMutation(SIGNUP_WITH_GOOGLE_MUTATION, {
    onCompleted: async (data) => {
      if (data.signupWithGoogle.success) {
        setErrorMessage(null);
        const token = data.signupWithGoogle.token;
        const userID = jwtDecode(token).user_id;
        await signIn({ token });
        navigate(`/profile/${userID}`);
      } else {
        setErrorMessage(data.signupWithGoogle.errors[0]);
        scrollToError();
        setSubmitting(false);
      }
    },
  });

  const onFinish = (values) => {
    setSubmitting(true);
    signupMutation({
      variables: {
        ...values,
        userType: "Participant",
        mobileNumber,
        guardianMobileNumber,
        emergencyContactNumber,
        dateOfBirth,
      },
    });
  };

  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line no-console
    console.log("Failed:", errorInfo);
  };

  const scrollToError = () => {
    if (errorMessageRef.current) {
      errorMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const countriesList = countryList()
    .setLabel("PS", "Palestine")
    .getData()
    .filter((obj) => obj.label !== "Israel")
    .map((obj) => (
      <Option key={obj.value} value={obj.label}>
        {obj.label}
      </Option>
    ));

  const handleSignInWithGoogle = async () => {
    try {
      await signInWithGoogle().then((result) => {
        // The signed-in user info.
        const user = result.user;

        signupWithGoogleMutation({
          variables: {
            email: user.email,
            fullName: user.displayName,
            uid: user.uid,
          },
        });
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setErrorMessage(error);
    }
  };

  const checkEmailExists = (email) => {
    if (email)
      checkIfEmailExistsMutation({
        variables: {
          email,
        },
      });
  };

  return (
    <>
      <div className="sign-up-gateways">
        <Button
          onClick={handleSignInWithGoogle}
          icon={<img src={GoogleLogo} width={18} alt="Google Logo" />}
        >
          {t("auth.signUp.signUpWithGoogle")}
        </Button>
      </div>

      <div className="divider-container">
        <Divider className="m-0">
          <p className="text-center my-25 font-semibold text-muted">{t("auth.signUp.or")}</p>
        </Divider>
      </div>

      <Form onFinish={onFinish} onFinishFailed={onFinishFailed} scrollToFirstError={true}>
        <div ref={errorMessageRef}></div> {/* Error message ref */}
        {errorMessage && <p className="text-danger english-field">{errorMessage}</p>}
        <Row gutter={[24, 0]}>
          <Col xs={24} lg={16}>
            <Form.Item
              className={emailExistsMessage ? "mb-0" : ""}
              label={t("auth.signUp.emailField")}
              name="email"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
                {
                  type: "email",
                  message: t("auth.signUp.validEmailAddressMessage"),
                },
              ]}
            >
              <Input className="english-field" onBlur={(e) => checkEmailExists(e.target.value)} />
            </Form.Item>
          </Col>
          {emailExistsMessage && (
            <Col xs={24}>
              <p className="text-danger">{t("auth.signUp.emailExistsMessage")}</p>
            </Col>
          )}
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} lg={12}>
            <Form.Item
              label={t("auth.signUp.passwordField")}
              name="password"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
            >
              <Input.Password
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                className="english-field"
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label={t("auth.signUp.confirmPasswordField")}
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
            >
              <Input.Password
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                className="english-field"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} lg={12}>
            <Form.Item
              label={t("auth.signUp.participantFullNameEnField")}
              name="fullNameEn"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
            >
              <Input maxLength={50} showCount className="english-field" />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label={t("auth.signUp.participantFullNameArField")}
              name="fullNameAr"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
            >
              <Input maxLength={50} showCount className="arabic-field" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} lg={12}>
            <Form.Item
              label={t("auth.signUp.civilIdNumberField")}
              name="civilIdNumber"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
                {
                  pattern: /^(?:\d*)$/,
                  message: t("auth.signUp.civilIdFormatMessage"),
                },
                {
                  min: 12,
                  max: 12,
                  message: t("auth.signUp.civilIdLengthMessage"),
                },
              ]}
            >
              <Input className="english-field" />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label={t("auth.signUp.dateOfBirthField")}
              name="dateOfBirth"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
            >
              <DatePicker
                format="YYYY-MM-DD"
                style={{ width: "100%" }}
                className="english-field"
                onChange={(dateObj, dateString) => {
                  // dateString will be in the format "YYYY-MM-DD"
                  setDateOfBirth(dateString);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} lg={12}>
            <Form.Item
              label={t("auth.signUp.mobileNumberField")}
              name="mobileNumber"
              help={t("auth.signUp.whatsAppCommunication")}
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
            >
              <PhoneInput
                className="english-field"
                country="kw"
                placeholder=""
                excludeCountries={["il"]}
                preferredCountries={["kw"]}
                onChange={(value, data) => {
                  const countryCode = data.dialCode;
                  const index = value.indexOf(countryCode);
                  const length = countryCode.length;
                  setMobileNumber(countryCode + "-" + value.slice(index + length));
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label={t("auth.signUp.genderField")}
              name="gender"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
              initialValue=""
            >
              <Select>
                <Option key="" value="">
                  -----
                </Option>
                ,
                <Option key="Male" value="Male">
                  {t("auth.signUp.maleOption")}
                </Option>
                ,
                <Option key="Female" value="Female">
                  {t("auth.signUp.femaleOption")}
                </Option>
                ,
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} lg={12}>
            <Form.Item
              label={t("auth.signUp.guardianMobileNumberField")}
              name="guardianMobileNumber"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
            >
              <PhoneInput
                className="english-field"
                country="kw"
                placeholder=""
                excludeCountries={["il"]}
                preferredCountries={["kw"]}
                onChange={(value, data) => {
                  const countryCode = data.dialCode;
                  const index = value.indexOf(countryCode);
                  const length = countryCode.length;
                  setGuardianMobileNumber(countryCode + "-" + value.slice(index + length));
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label={t("auth.signUp.guardianRelationField")}
              name="guardianRelation"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} lg={12}>
            <Form.Item
              label={t("auth.signUp.emergencyContactNumberField")}
              name="emergencyContactNumber"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
            >
              <PhoneInput
                className="english-field"
                country="kw"
                placeholder=""
                excludeCountries={["il"]}
                preferredCountries={["kw"]}
                onChange={(value, data) => {
                  const countryCode = data.dialCode;
                  const index = value.indexOf(countryCode);
                  const length = countryCode.length;
                  setEmergencyContactNumber(countryCode + "-" + value.slice(index + length));
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label={t("auth.signUp.emergencyContactRelationField")}
              name="emergencyContactRelation"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} lg={12}>
            <Form.Item
              label={t("auth.signUp.languagesField")}
              name="languages"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
              initialValue=""
            >
              <Checkbox.Group
                style={{ width: "100%" }}
                onChange={(checkedValues) => {
                  setLanguages(checkedValues);
                }}
              >
                <Row className="mb-1 mx-0">
                  <Col>
                    <Checkbox value="Arabic">{t("auth.signUp.arabicOption")}</Checkbox>
                  </Col>
                </Row>

                <Row className="mb-1 mx-0">
                  <Col>
                    <Checkbox value="English">{t("auth.signUp.englishOption")}</Checkbox>
                  </Col>
                </Row>

                <Row className="mb-1 mx-0">
                  <Col>
                    <Checkbox value="Others">{t("auth.signUp.othersOption")}</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Col>

          {languages.includes("Others") && (
            <Col xs={24} lg={12}>
              <Form.Item
                label={t("auth.signUp.otherLanguagesField")}
                name="otherLanguages"
                rules={[
                  {
                    required: true,
                    message: t("auth.signUp.requiredFieldMessage"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} lg={12}>
            <Form.Item
              label={t("auth.signUp.chronicDiseasesYesNoField")}
              name="chronicDiseasesYesNo"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
              initialValue=""
            >
              <Select onChange={(value) => setChronicDiseasesYesNoField(value)}>
                <Option key="" value="">
                  -----
                </Option>
                ,
                <Option key="Yes" value="Yes">
                  {t("auth.signUp.yesOption")}
                </Option>
                ,
                <Option key="No" value="No">
                  {t("auth.signUp.noOption")}
                </Option>
                ,
              </Select>
            </Form.Item>
          </Col>

          {chronicDiseasesYesNo === "Yes" && (
            <Col xs={24} lg={12}>
              <Form.Item
                label={t("auth.signUp.chronicDiseasesField")}
                name="chronicDiseases"
                rules={[
                  {
                    required: true,
                    message: t("auth.signUp.requiredFieldMessage"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} lg={8}>
            <Form.Item
              label={t("auth.signUp.nationalityField")}
              name="nationality"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
              initialValue=""
            >
              <Select showSearch className="english-field">
                <Option key="" value="">
                  -----
                </Option>
                ,{countriesList},
                <Option key="Undefined" value="Undefined">
                  Undefined (غير محددي الجنسية)
                </Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} lg={8}>
            <Form.Item
              label={t("auth.signUp.motherNationalityField")}
              name="motherNationality"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
              initialValue=""
            >
              <Select showSearch className="english-field">
                <Option key="" value="">
                  -----
                </Option>
                ,{countriesList},
                <Option key="Undefined" value="Undefined">
                  Undefined (غير محددي الجنسية)
                </Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} lg={8}>
            <Form.Item
              label={t("auth.signUp.countryOfResidenceField")}
              name="countryOfResidence"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
              initialValue=""
            >
              <Select showSearch className="english-field">
                <Option key="" value="">
                  -----
                </Option>
                ,{countriesList}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]} className="mb-3">
          <Col xs={24}>
            <Space>
              <InfoCircleFilled />
              <span>{t("auth.signUp.participantNote")}</span>
            </Space>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            <Form.Item className="text-center">
              <Button
                style={{ width: "60%" }}
                type="primary"
                htmlType="submit"
                loading={submitting}
                disabled={submitting}
              >
                {t("auth.signUp.signUpBtn")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default ParticipantForm;
