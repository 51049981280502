import { useState, useEffect } from "react";

import { Modal, Descriptions } from "antd";

import { useTranslation } from "react-i18next";

import { REQUEST_DETAILS } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";

import { Loading } from "components/Loading";

import ModalStyles from "./styles";

const RequestDetailsModal = ({ isModalOpen, handleOk, handleCancel, requestID }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [items, setItems] = useState([]);
  const [interviewersInfo, setInterviewsInfo] = useState([]);
  const [locationsInfo, setLocationsInfo] = useState([]);

  const { loading: requestDetailsDataLoading, data: requestDetailsData } = useQuery(
    REQUEST_DETAILS,
    { variables: { id: requestID } },
  );

  useEffect(() => {
    if (requestDetailsData?.requestDetails) {
      setItems([
        {
          key: "trainingType",
          label: t("requestForm.programInfoForm.trainingTypeField"),
          span: 3,
          children: requestDetailsData.requestDetails.trainingType,
        },
        {
          key: "specialRequirements",
          label: t("requestForm.programInfoForm.specialRequirementsField"),
          span: 3,
          children: requestDetailsData.requestDetails.specialRequirements,
        },
        {
          key: "trainingType",
          label: t("requestForm.programInfoForm.certificateCopyField"),
          span: 3,
          children: requestDetailsData.requestDetails.certificateCopy ? (
            <a
              href={requestDetailsData.requestDetails.certificateCopy}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("requestForm.programInfoForm.viewCurrentCopy")}
            </a>
          ) : (
            "-"
          ),
        },
      ]);

      const interviewersData = [];

      requestDetailsData.requestDetails.interviewers.forEach((interviewer, index) => {
        const nameObj = {
          key: "name#" + index,
          label: t("requestForm.interviewersInfoForm.nameField") + " #" + (index + 1),
          children: <span>{interviewer.name}</span>,
          span: 2,
        };

        const numberObj = {
          key: "number#" + index,
          label: t("requestForm.interviewersInfoForm.numberField") + " #" + (index + 1),
          children: (
            <span style={{ unicodeBidi: "embed", direction: "ltr" }}>{interviewer.number}</span>
          ),
          span: 2,
        };

        interviewersData.push(nameObj, numberObj);
      });
      setInterviewsInfo(interviewersData);

      const locationsData = [];

      requestDetailsData.requestDetails.locations.forEach((location, index) => {
        const addressObj = {
          key: "address#" + index,
          label: t("requestForm.locationsForm.locationAddress") + " #" + (index + 1),
          children: <span>{location.address}</span>,
          span: 2,
        };

        const urlObj = {
          key: "url#" + index,
          label: t("requestForm.locationsForm.locationUrl") + " #" + (index + 1),
          children: (
            <a href={location.url} target="_blank" rel="noopener noreferrer">
              {location.url}
            </a>
          ),
          span: 2,
        };

        const supervisorNameObj = {
          key: "supervisorName#" + index,
          label: t("requestForm.locationsForm.supervisorNameField") + " #" + (index + 1),
          children: <span>{location.supervisorName}</span>,
          span: 2,
        };

        const supervisorNumberObj = {
          key: "supervisorNumber#" + index,
          label: t("requestForm.locationsForm.supervisorNumberField") + " #" + (index + 1),
          children: <span>{location.supervisorNumber}</span>,
          span: 2,
        };

        locationsData.push(addressObj, urlObj, supervisorNameObj, supervisorNumberObj);
      });
      setLocationsInfo(locationsData);
    }
  }, [requestDetailsData]);

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      key={requestID}
      width={1000}
      footer={null}
    >
      {requestDetailsDataLoading ? (
        <Loading />
      ) : (
        <ModalStyles currentLanguage={currentLanguage}>
          <Descriptions
            layout="vertical"
            bordered
            items={items}
            title={t("dashboard.requestsTable.requestDetailsModal.title")}
          />

          <Descriptions
            layout="vertical"
            bordered
            items={interviewersInfo}
            title={t("requestForm.collapseHeader.interviewersInfo")}
            className="mt-4"
          />

          <Descriptions
            layout="vertical"
            bordered
            items={locationsInfo}
            title={t("requestForm.collapseHeader.locationsInfo")}
            className="mt-4"
          />
        </ModalStyles>
      )}
    </Modal>
  );
};

export default RequestDetailsModal;
