import styled from "styled-components";

const FooterContainer = styled.div`
  background-color: #212121;
  padding: 48px;

  .ant-menu-dark {
    background-color: #212121;
  }

  .ant-menu-dark .ant-menu-item {
    color: #fff;
  }

  .ant-menu-dark .ant-menu-item-disabled .ant-menu-title-content {
    color: rgba(255, 255, 255, 0.5);
  }

  .ant-menu-item-divider {
    display: none;
  }

  .ant-menu-item-selected {
    background-color: transparent !important; // Remove background color when item is selected
  }

  .icon {
    font-size: 16px;
    color: #fff;
    background-color: transparent;
    margin: 0 4px;
  }

  a {
    text-decoration: none;
  }
`;

export default FooterContainer;
