import React from "react";

import { Layout, Row, Col } from "antd";

import DashboardSideNav from "./DashboardSideNav";

import SideNavStyles from "./styles";

type Props = {
  children: React.ReactNode;
};

const { Content } = Layout;

const SideNav = ({ children }: Props) => {
  return (
    <SideNavStyles>
      <Layout>
        <Row gutter={[24, 0]}>
          <Col xs={24} md={8} lg={5} className="mb-5 mb-lg-0">
            <DashboardSideNav />
          </Col>

          <Col xs={24} md={16} lg={19}>
            <Content style={{ minHeight: 280, overflowX: "clip" }}>{children}</Content>
          </Col>
        </Row>
      </Layout>
    </SideNavStyles>
  );
};

export default SideNav;
