import { MenuOutlined } from "@ant-design/icons";
import { Button, Drawer, Layout } from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

// import LeftMenu from "./LeftMenu";
import RightMenu from "./RightMenu";
import NavbarStyles from "./styles";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(!open);
  };

  // auto-close the mobile drawer when a path is selected
  const { pathname: location } = useLocation();
  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <NavbarStyles>
      <nav className="navbar">
        <Layout>
          <Layout.Header className="nav-header px-2 px-md-0">
            <Link to="/" className="logo">
              <img className="brand-font" src="/logo.svg" alt="Waed Logo" width="80" />
            </Link>

            {/* <LeftMenu mode="horizontal" /> */}

            <RightMenu mode="horizontal" />

            <Button className="menuButton" type="text" onClick={showDrawer}>
              <MenuOutlined />
            </Button>

            <Drawer
              title={<img className="brand-font" src="/logo.svg" alt="Waed Logo" width="80" />}
              placement="right"
              closable={true}
              onClose={showDrawer}
              open={open}
              style={{ zIndex: 99999 }}
            >
              {/* <LeftMenu mode="inline" /> */}
              <RightMenu mode="inline" />
            </Drawer>
          </Layout.Header>
        </Layout>
      </nav>
    </NavbarStyles>
  );
};

export default Navbar;
