import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

import styled from "styled-components";

import { Button } from "antd";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";

// import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import logo from "../../../assets/images/logo.png";

import RubikRegular from "../../../assets/fonts/Rubik-Regular.ttf";
import RubikBold from "../../../assets/fonts/Rubik-Bold.ttf";

import { DateTime } from "luxon";

import { DATE_FORMAT } from "constants/datetime";
// import { getFileSrc } from "helpers/common";

Font.register({
  family: "Rubik",
  fonts: [
    { src: RubikRegular, fontWeight: "normal" },
    { src: RubikBold, fontWeight: "bold" },
  ],
});
const InternInformationPDF = ({ obj }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  // Create styles
  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "#E4E4E4",
      padding: 20,
    },
    logoView: {
      flexDirection: "row",
      alignItems: "flex-start",
    },
    logo: {
      width: 80,
    },
    titleView: {
      alignItems: "center",
    },
    title: {
      fontFamily: "Rubik",
      fontWeight: "bold",
      fontSize: 24,
      textAlign: "center",
      margin: 20,
    },
    contentView: {
      padding: 20,
      flexGrow: 1,
      textAlign: "center",
    },
    text: {
      textAlign: currentLanguage === "ar" ? "right" : "left",
      direction: currentLanguage === "ar" ? "rtl" : "ltr",
      fontFamily: "Rubik",
      marginBottom: 20,
      fontSize: 12,
    },
    mobileNumberText: {
      unicodeBidi: "embed",
      direction: "ltr",
      fontFamily: "Rubik",
      fontSize: 12,
    },
    imageView: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      marginBottom: 20,
    },
    image: {
      width: 100,
      height: 100,
    },
  });

  // TODO: Remove as no longer needed.
  // const [questionAnswersData, setQuestionAnswersData] = useState([]);
  // const [questionAnswersDataLoading, setQuestionAnswersDataLoading] = useState(true);

  // useEffect(() => {
  //   const fetchData = async (fileURL) => {
  //     try {
  //       const result = await getFileSrc(fileURL);
  //       return result;
  //     } catch (error) {
  //       return null;
  //     }
  //   };

  //   const fetchFileSources = async () => {
  //     const updatedQuestionAnswersData = [];
  //     for (const questionAnswerObj of obj.questionAnswers) {
  //       if (questionAnswerObj.answer.includes("https://api.typeform.com/")) {
  //         const fileSrc = await fetchData(questionAnswerObj.answer);
  //         updatedQuestionAnswersData.push({
  //           ...questionAnswerObj,
  //           fileSrc,
  //         });
  //       } else {
  //         updatedQuestionAnswersData.push(questionAnswerObj);
  //       }
  //     }
  //     setQuestionAnswersData(updatedQuestionAnswersData);
  //     setQuestionAnswersDataLoading(false);
  //   };

  //   fetchFileSources();
  // }, [obj.questionAnswers]);

  // if (questionAnswersDataLoading) return <Button icon={<LoadingOutlined />} className="icon-btn" />;

  const MyDoc = () => (
    <Document>
      <Page wrap size="A4" style={styles.page}>
        {/* Company logo */}
        <View style={styles.logoView} fixed>
          <Image src={logo} style={styles.logo} />
        </View>

        {/* Page title */}
        <View style={styles.titleView} fixed>
          <Text style={styles.title}>
            {t("dashboard.requestsTable.assignedInternsModal.PDFTitle")}
          </Text>
        </View>

        <View style={styles.contentView}>
          {/* Intern information */}
          <Text style={styles.text}>
            {t("dashboard.requestsTable.assignedInternsModal.participantID")}
            {obj.participantProfile.participantId}
          </Text>

          <Text style={styles.text}>
            {t("dashboard.requestsTable.assignedInternsModal.fullNameEn")}
            {obj.participantProfile.fullNameEn}
          </Text>

          <Text style={styles.text}>
            {t("dashboard.requestsTable.assignedInternsModal.fullNameAr")}
            {obj.participantProfile.fullNameAr}
          </Text>

          <Text style={styles.text}>
            {t("dashboard.requestsTable.assignedInternsModal.assignedCompanyName")}
            {obj.assignedJobOpportunity?.companyRequest?.[
              `companyName${currentLanguage === "en" ? "En" : "Ar"}`
            ] || "-"}
          </Text>

          <Text style={styles.text}>
            {t("dashboard.requestsTable.assignedInternsModal.assignedJobTitle")}
            {obj.assignedJobOpportunity?.jobTitle || "-"}
          </Text>

          <Text style={styles.text}>
            {t("dashboard.requestsTable.assignedInternsModal.status")}
            {t(`dashboard.${obj.status.toLowerCase()}`).toUpperCase()}
          </Text>

          <Text style={styles.text}>
            {t("dashboard.requestsTable.assignedInternsModal.paymentStatus")}
            {t(`dashboard.${obj.paymentStatus.toLowerCase()}`).toUpperCase()}
          </Text>

          <Text style={styles.text}>
            {t("dashboard.requestsTable.assignedInternsModal.emailAddress")}
            {obj.participantProfile.user.email}
          </Text>

          <Text style={styles.text}>
            {t("dashboard.requestsTable.assignedInternsModal.civilIDNumber")}
            {obj.participantProfile.civilIdNumber}
          </Text>

          <Text style={styles.text}>
            {t("dashboard.requestsTable.assignedInternsModal.dateOfBirth")}
            {DateTime.fromISO(obj.participantProfile.dateOfBirth).toFormat(DATE_FORMAT)}
          </Text>

          <Text style={styles.text}>
            {t("dashboard.requestsTable.assignedInternsModal.age")}
            {obj.participantProfile.age}
          </Text>

          <Text style={styles.text}>
            {t("dashboard.requestsTable.assignedInternsModal.gender")}
            {t(`dashboard.genderOptions.${obj.participantProfile.gender}`)}
          </Text>

          <Text style={styles.text}>
            <Text>{t("dashboard.requestsTable.assignedInternsModal.mobileNumber")}</Text>

            <Text style={{ unicodeBidi: "embed", direction: "ltr" }}>
              {obj.participantProfile.mobileNumber}
            </Text>
          </Text>

          <Text style={styles.text}>
            {t("dashboard.requestsTable.assignedInternsModal.guardianMobileNumber")}
            {obj.participantProfile.guardianMobileNumber}
          </Text>

          <Text style={styles.text}>
            {t("dashboard.requestsTable.assignedInternsModal.guardianRelation")}
            {obj.participantProfile.guardianRelation}
          </Text>

          <Text style={styles.text}>
            {t("dashboard.requestsTable.assignedInternsModal.emergencyContactNumber")}
            {obj.participantProfile.emergencyContactNumber}
          </Text>

          <Text style={styles.text}>
            {t("dashboard.requestsTable.assignedInternsModal.emergencyContactRelation")}
            {obj.participantProfile.emergencyContactRelation}
          </Text>

          <Text style={styles.text}>
            {t("dashboard.requestsTable.assignedInternsModal.languages")}
            {obj.participantProfile.languages}
          </Text>

          <Text style={styles.text}>
            {t("dashboard.requestsTable.assignedInternsModal.otherLanguages")}
            {obj.participantProfile.otherLanguages || "-"}
          </Text>

          <Text style={styles.text}>
            {t("dashboard.requestsTable.assignedInternsModal.chronicDiseasesYesNo")}
            {t(
              `dashboard.${obj.participantProfile.chronicDiseasesYesNo.toLowerCase()}`,
            ).toUpperCase()}
          </Text>

          <Text style={styles.text}>
            {t("dashboard.requestsTable.assignedInternsModal.chronicDiseases")}
            {obj.participantProfile.chronicDiseases || "-"}
          </Text>

          <Text style={styles.text}>
            {t("dashboard.requestsTable.assignedInternsModal.nationality")}
            {obj.participantProfile.nationality}
          </Text>

          <Text style={styles.text}>
            {t("dashboard.requestsTable.assignedInternsModal.motherNationality")}
            {obj.participantProfile.motherNationality}
          </Text>

          <Text style={styles.text}>
            {t("dashboard.requestsTable.assignedInternsModal.countryOfResidence")}
            {obj.participantProfile.countryOfResidence}
          </Text>

          <Text style={styles.text}>
            {t("registrationForm.stepTwo.valuesYouBelieveInField")}&nbsp;
            {obj.valuesYouBelieveIn || "-"}
          </Text>

          <Text style={styles.text}>
            {t("registrationForm.stepTwo.howDidYouKnowAboutTheProgramField")}&nbsp;
            {obj.howDidYouKnowAboutTheProgram || "-"}
          </Text>

          {obj.civilId ? (
            <View>
              <View>
                <Text style={styles.text}>{t("registrationForm.stepTwo.civilIdField")}:</Text>
              </View>

              <View style={styles.imageView}>
                <Image src={obj.civilId} style={styles.image} />
              </View>
            </View>
          ) : (
            <Text style={styles.text}>{t("registrationForm.stepTwo.civilIdField")}:&nbsp;-</Text>
          )}

          {obj.personalImage ? (
            <View>
              <View>
                <Text style={styles.text}>{t("registrationForm.stepTwo.personalImageField")}:</Text>
              </View>

              <View style={styles.imageView}>
                <Image src={obj.personalImage} style={styles.image} />
              </View>
            </View>
          ) : (
            <Text style={styles.text}>
              {t("registrationForm.stepTwo.personalImageField")}:&nbsp; -
            </Text>
          )}

          <Text style={styles.text}>
            {t("registrationForm.stepTwo.specialRequestField")}:&nbsp;
            {obj.specialRequest || "-"}
          </Text>

          <Text style={styles.text}>
            {t("registrationForm.stepThree.agreeToAttend")}&nbsp;
            {obj.programRequirements ? t("auth.signUp.yesOption") : t("auth.signUp.noOption")}
          </Text>

          <Text style={styles.text}>
            {t("registrationForm.stepThree.correctInformation")}&nbsp;
            {obj.correctInformation ? t("auth.signUp.yesOption") : t("auth.signUp.noOption")}
          </Text>

          {/* TODO: Remove as no longer needed. */}
          {/* {questionAnswersData.map((questionAnswerObj, index) => {
            if ("fileSrc" in questionAnswerObj && questionAnswerObj.fileSrc) {
              return (
                <View key={index}>
                  <View>
                    <Text style={styles.text}>{questionAnswerObj.question}:</Text>
                  </View>

                  <View style={styles.imageView}>
                    <Image src={questionAnswerObj.fileSrc} style={styles.image} />
                  </View>
                </View>
              );
            } else {
              return (
                <Text key={index} style={styles.text}>
                  {questionAnswerObj.question}: {questionAnswerObj.answer}
                </Text>
              );
            }
          })} */}
        </View>
      </Page>
    </Document>
  );

  return (
    <PDFDownloadLink document={<MyDoc />} fileName={obj.participantProfile.fullNameEn + ".pdf"}>
      {({ loading }) =>
        loading ? (
          <StyledIcon icon={<LoadingOutlined />} className="icon-btn"></StyledIcon>
        ) : (
          <StyledIcon icon={<DownloadOutlined />} className="icon-btn"></StyledIcon>
        )
      }
    </PDFDownloadLink>
  );
};

export default InternInformationPDF;

const StyledIcon = styled(Button)`
  border-radius: 50px;
  border-color: transparent;

  box-shadow: none;

  &:hover {
    background: #35b4a7 !important;

    svg {
      color: #fff;
    }
  }
`;
