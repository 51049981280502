import React from "react";

import ParticipantProfile from "../components/Profile/ParticipantProfile";
import CompanyProfile from "../components/Profile/CompanyProfile";

import useAuth from "../auth";

const Profile = () => {
  const { isAuthenticated, user } = useAuth();

  return isAuthenticated && user.userType === "Participant" ? (
    <ParticipantProfile />
  ) : isAuthenticated && user.userType === "Company" ? (
    <CompanyProfile />
  ) : null;
};

export default Profile;
