import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Form, Row, Col, Button, Checkbox, Result, Typography } from "antd";

import RequestFormContainer from "./styles";

import ProgramInfoForm from "./ProgramInfoForm";
import InterviewersInfoForm from "./InterviewersInfoForm";
import LocationsForm from "./LocationsForm";

import { EDIT_COMPANY_REQUEST_MUTATION } from "../../graphql/mutations";
import { REQUEST_DETAILS } from "../../graphql/queries";
import { useMutation, useQuery } from "@apollo/client";

import { Loading } from "components/Loading";

import moment from "moment";

const { Title } = Typography;

const RequestUpdateFrom = () => {
  const { t } = useTranslation();

  const { requestID } = useParams();

  const {
    loading: requestDetailsDataLoading,
    data: requestDetailsData,
    error: requestDetailsDataError,
  } = useQuery(REQUEST_DETAILS, { variables: { id: requestID } });

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const errorMessageRef = useRef(null);
  const successMessageRef = useRef(null);

  const [submitting, setSubmitting] = useState(false);

  // wait till the form initial values are calculated, as the form won't re-render.
  const [valuesLoading, setValuesLoading] = useState(true);

  const [programInfoInputFields, setProgramInfoInputFields] = useState({});
  const [interviewersInfoInputFields, setInterviewersInfoInputFields] = useState([]);
  const [locationsInfoInputFields, setLocationsInfoInputFields] = useState([]);

  const [certificateCopy, setCertificateCopy] = useState(null);

  useEffect(() => {
    if (!requestDetailsDataLoading && !requestDetailsDataError && requestDetailsData) {
      if (requestDetailsData.requestDetails.certificateCopy)
        setCertificateCopy(requestDetailsData.requestDetails.certificateCopy);
      setProgramInfoInputFields({
        trainingType: requestDetailsData.requestDetails.trainingType,
        specialRequirements: requestDetailsData.requestDetails.specialRequirements,
      });
      setInterviewersInfoInputFields(
        requestDetailsData.requestDetails.interviewers.map((obj) => {
          return { id: obj.id, name: obj.name, number: obj.number };
        }),
      );
      setLocationsInfoInputFields(
        requestDetailsData.requestDetails.locations.map((obj) => {
          return {
            id: obj.id,
            address: obj.address,
            url: obj.url,
            supervisorName: obj.supervisorName,
            supervisorNumber: obj.supervisorNumber,
            jobOpportunities: obj.jobOpportunities.map((obj2) => {
              return {
                id: obj2.id,
                jobTitle: obj2.jobTitle,
                jobDescription: obj2.jobDescription,
                malesNumber: obj2.malesNumber,
                femalesNumber: obj2.femalesNumber,
                startingTime: moment(obj2.startingTime, "HH:mm:ss").format("HH:mm"),
                endingTime: moment(obj2.endingTime, "HH:mm:ss").format("HH:mm"),
                workDays: obj2.workDays,
                specialNotes: obj2.specialNotes,
              };
            }),
          };
        }),
      );

      setValuesLoading(false);
    }
  }, [requestDetailsDataLoading, requestDetailsDataError, requestDetailsData]);

  const [editCompanyRequestMutation] = useMutation(EDIT_COMPANY_REQUEST_MUTATION, {
    onCompleted: (data) => {
      if (data.editCompanyRequest.success) {
        setErrorMessage(null);
        setSuccessMessage(t("requestForm.savedSuccessfullyMessage"));
        scrollToSuccess();
        setTimeout(() => {
          setSuccessMessage(null);
          window.location.reload();
        }, 5000); // Clear success message after 5 seconds
      } else {
        setErrorMessage(data.editCompanyRequest.errors[0]);
        scrollToError();
        setSubmitting(false);
      }
    },
  });

  const onFinish = (values) => {
    // setSubmitting(true);

    const data = {
      ...programInfoInputFields,
      requestId: requestID,
      accurateInformation: values.accurateInformation,
      interviewersInfoForm: interviewersInfoInputFields,
      locationsInfoForm: locationsInfoInputFields,
    };

    editCompanyRequestMutation({
      variables: { ...data },
    });
  };

  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line no-console
    console.log("Failed:", errorInfo);
  };

  const scrollToError = () => {
    if (errorMessageRef.current) {
      errorMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const scrollToSuccess = () => {
    if (successMessageRef.current) {
      successMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  // if there is an error, return the error message not the valuesLoading.
  if (requestDetailsDataLoading || (valuesLoading && !requestDetailsDataError)) return <Loading />;

  if (requestDetailsDataError)
    return (
      <RequestFormContainer>
        <Result
          status="error"
          title={t("requestForm.errorFetchingDataMessage")}
          subTitle={requestDetailsDataError.message}
        />
      </RequestFormContainer>
    );

  return (
    <RequestFormContainer>
      <Title
        className="mb-3 mt-0"
        style={{
          fontSize: "30px",
          whiteSpace: "break-spaces",
          textAlign: "center",
        }}
      >
        {t("requestForm.formTitle")}
      </Title>

      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        className="row-col"
        scrollToFirstError={true}
      >
        <div ref={errorMessageRef}></div> {/* Error message ref */}
        {errorMessage && <p className="text-danger english-field">{errorMessage}</p>}
        <div ref={successMessageRef}></div> {/* Success message ref */}
        {successMessage && <p className="text-success">{successMessage}</p>}
        <Row>
          <Col xs={24}>
            <ProgramInfoForm
              inputFields={programInfoInputFields}
              setInputFields={setProgramInfoInputFields}
              certificateCopy={certificateCopy}
            />
          </Col>

          <Col xs={24}>
            <InterviewersInfoForm
              inputFields={interviewersInfoInputFields}
              setInputFields={setInterviewersInfoInputFields}
            />
          </Col>

          <Col xs={24}>
            <LocationsForm
              inputFields={locationsInfoInputFields}
              setInputFields={setLocationsInfoInputFields}
            />
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            <Form.Item
              valuePropName="checked"
              name="accurateInformation"
              rules={[
                {
                  required: true,
                  message: t("requestForm.requiredFieldMessage"),
                },
              ]}
            >
              <Checkbox>{t("requestForm.accurateInformationField")}</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]} justify="center">
          <Col xs={24} md={12}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={submitting}
                disabled={submitting}
              >
                {t("requestForm.submitBtn")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </RequestFormContainer>
  );
};

export default RequestUpdateFrom;
