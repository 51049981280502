import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Button, Col, Layout, Row, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import useAuth from "../../../auth";
import { GET_HERO_SECTION_DETAILS } from "../../../graphql/queries";
import { Loading } from "../../Loading";
import HeroSectionContainer from "./styles";

const { Title, Text } = Typography;
const { Content } = Layout;

const Hero = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const { isAuthenticated } = useAuth();

  const { loading: heroSectionDetailsLoading, data: heroSectionDetailsData } =
    useQuery(GET_HERO_SECTION_DETAILS);

  if (heroSectionDetailsLoading) return <Loading />;

  return (
    <HeroSectionContainer>
      <Layout className="layout-default">
        <Content>
          <Row gutter={[24, 40]} justify="space-around">
            <Col
              lg={12}
              xs={24}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {/* title */}
              <Title
                className="mb-4 mt-0"
                style={{
                  fontSize: "45px",
                  fontWeight: "bolder",
                }}
              >
                {
                  heroSectionDetailsData.heroSectionDetails[
                    "title" + (currentLanguage === "en" ? "En" : "Ar")
                  ]
                }
              </Title>
              {/* mission & vision */}
              <Text
                style={{
                  fontSize: "16px",
                }}
              >
                {
                  heroSectionDetailsData.heroSectionDetails[
                    "mission" + (currentLanguage === "en" ? "En" : "Ar")
                  ]
                }
                &nbsp;
                {
                  heroSectionDetailsData.heroSectionDetails[
                    "vision" + (currentLanguage === "en" ? "En" : "Ar")
                  ]
                }
              </Text>
              {/* buttons */}
              <Row gutter={[24, 24]} className="mt-5" direction="rtl">
                {!isAuthenticated && (
                  <Col>
                    <Link
                      to="/sign-in"
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <Button type="primary" className="button-with-padding">
                        {t("auth.signIn.signInBtn")}
                      </Button>
                    </Link>
                  </Col>
                )}
                <Col>
                  <a
                    href="#our-story"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <Button
                      type="text"
                      className="button-with-padding"
                      style={!isAuthenticated ? { boxShadow: "none" } : {}}
                    >
                      <Space>
                        <span>{t("home.hero.learnMore")}</span>
                        {currentLanguage === "en" ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
                      </Space>
                    </Button>
                  </a>
                </Col>
              </Row>
            </Col>
            {/* show image only on lg and xl screens */}
            <Col lg={12} xs={24} className="img-col d-none d-lg-flex d-xl-flex">
              <img src={heroSectionDetailsData.heroSectionDetails.image} alt="" />
            </Col>
          </Row>
        </Content>
      </Layout>
    </HeroSectionContainer>
  );
};

export default Hero;
