import { auth } from "configs/firebase";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userLoading, setUserLoading] = useState(true);

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      const currentTime = Date.now() / 1000;
      const { exp } = jwtDecode(token);
      if (exp < currentTime) {
        Cookies.remove("token");
        setUser(null);
        setUserLoading(false);
      } else {
        const { email, user_id, user_type, uid, is_verified } = jwtDecode(token);

        setUser({ email, userID: user_id, userType: user_type, uid, isVerified: is_verified });
        setUserLoading(false);
      }
    } else {
      setUser(null);
      setUserLoading(false);
    }
  }, []);

  const hasUsedSocialAuth = user?.uid;

  const logout = () => {
    Cookies.remove("token");
    setUser(null);

    if (hasUsedSocialAuth) {
      auth.signOut();
    }
  };

  const signIn = ({ token }) => {
    Cookies.set("token", token, { expires: 1 });
    const { email, user_id, user_type, uid, is_verified } = jwtDecode(token);
    setUser({ email, userID: user_id, userType: user_type, uid, isVerified: is_verified });
    setUserLoading(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        userLoading,
        signIn,
        logout,
        setUser,
        hasUsedSocialAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}
