import styled from "styled-components";

const PasswordResetFormContainer = styled.div`
  padding: 40px 0;

  .ant-layout {
    background: transparent;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-layout-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-width: 50vw;
  }

  .ant-form-item-explain-error {
    text-align: ${(props) => (props.currentLanguage === "ar" ? "right" : "left")};
  }
`;

export default PasswordResetFormContainer;
