import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button, Col, Form, Input, Layout, Row, Typography, Alert } from "antd";
import { signInWithGoogle } from "configs/firebase";
import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import GoogleLogo from "../../assets/images/Google__G__Logo.png";
import signinImg from "../../assets/images/signin.png";
import useAuth from "../../auth";
import { SIGNIN_MUTATION, SIGNUP_WITH_GOOGLE_MUTATION } from "../../graphql/mutations";
import SignInFormContainer from "./styles";
import { jwtDecode } from "jwt-decode";
import ForgotPassword from "components/ForgotPassword";

const { Title, Text } = Typography;
const { Content } = Layout;

const SignInForm = () => {
  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const [showAlert, setShowAlert] = useState(false);

  const errorMessageRef = useRef(null);

  const { signIn } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    const passwordResetSuccessfullyMessage = localStorage.getItem(
      "passwordResetSuccessfullyMessage",
    );
    if (passwordResetSuccessfullyMessage === "true") {
      setShowAlert(true);

      localStorage.removeItem("passwordResetSuccessfullyMessage");

      // Automatically hide the alert after 5 seconds
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
  }, []);

  const [signinMutation] = useMutation(SIGNIN_MUTATION, {
    onCompleted: async (data) => {
      if (data.tokenAuth.success) {
        setErrorMessage(null);
        await signIn({ token: data.tokenAuth.token });
        const { user_type, user_id, is_verified } = jwtDecode(data.tokenAuth.token);
        if (!is_verified) navigate(`/account/verify`);
        else if (user_type === "Participant") navigate(`/history/${user_id}`);
        else navigate("/");
      } else {
        setErrorMessage(data.tokenAuth.errors[0]);
        scrollToError();
        setSubmitting(false);
      }
    },
  });

  const onFinish = (values) => {
    setSubmitting(true);
    signinMutation({ variables: { ...values } });
  };

  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line no-console
    console.log("Failed:", errorInfo);
  };

  const scrollToError = () => {
    if (errorMessageRef.current) {
      errorMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const [signupWithGoogleMutation] = useMutation(SIGNUP_WITH_GOOGLE_MUTATION, {
    onCompleted: (data) => {
      if (data.signupWithGoogle.success) {
        setErrorMessage(null);
        signIn({ token: data.signupWithGoogle.token });
        navigate("/");
      } else {
        setErrorMessage(data.signupWithGoogle.errors[0]);
        scrollToError();
        setSubmitting(false);
      }
    },
  });

  const handleSignInWithGoogle = async () => {
    try {
      await signInWithGoogle()
        .then((result) => {
          // The signed-in user info.
          const user = result.user;

          signupWithGoogleMutation({
            variables: {
              fullName: user.displayName,
              email: user.email,
              uid: user.uid,
            },
          });
        })
        .catch((error) => {
          if (error.code === "auth/popup-closed-by-user") {
            // Display a user-friendly message or UI element
            setErrorMessage("The sign-in popup was closed. Please try again.");
          } else {
            // Handle other errors
            setErrorMessage("An error occurred during sign-in. Please try again.");
          }
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("Error signing in with Google", error);
    }
  };

  return (
    <SignInFormContainer>
      {showAlert && (
        <Row gutter={[24, 40]} className="mb-3">
          <Col xs={24}>
            <Alert
              message={t("auth.forgotPassword.passwordResetForm.passwordResetSuccessfullyMessage")}
              type="success"
              closable
              onClose={() => setShowAlert(false)} // Close the alert when clicked
            />
          </Col>
        </Row>
      )}

      <Layout className="layout-default layout-signin">
        <Content className="signin">
          <Row gutter={[24, 0]}>
            <Col
              xs={24}
              lg={8}
              className="mb-5 mb-lg-0"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Title
                className="mb-5 mt-0"
                style={{
                  fontSize: "45px",
                  whiteSpace: "break-spaces",
                }}
              >
                {t("auth.signIn.title")}
              </Title>

              <Text
                style={{
                  fontSize: "20px",
                  whiteSpace: "break-spaces",
                }}
              >
                {t("auth.signIn.signUpPrompt")}
                <Link to="/user-type" className="font-bold" style={{ color: "#35b4a7" }}>
                  {t("auth.signIn.signUpLink")}
                </Link>
              </Text>
            </Col>

            {/* show only on lg and xl devices */}
            <Col className="sign-img mb-5 mb-lg-0 d-none d-lg-block d-xl-block" lg={8}>
              <img src={signinImg} alt="" />
            </Col>

            <Col
              xs={{ span: 24, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
                scrollToFirstError={true}
              >
                <div ref={errorMessageRef}></div> {/* Error message ref */}
                {errorMessage && (
                  <p
                    className="text-danger english-field"
                    style={{
                      whiteSpace: "break-spaces",
                    }}
                  >
                    {errorMessage}
                  </p>
                )}
                <Form.Item
                  className="username"
                  label={t("auth.signIn.email")}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t("auth.signIn.requiredFieldMessage"),
                    },
                    {
                      type: "email",
                      message: t("auth.signIn.validEmailAddressMessage"),
                    },
                  ]}
                >
                  <Input placeholder="example@address.com" className="english-field" />
                </Form.Item>
                <Form.Item
                  className="username"
                  label={t("auth.signIn.password")}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t("auth.signIn.requiredFieldMessage"),
                    },
                  ]}
                >
                  <Input.Password
                    className="english-field"
                    placeholder="•••••"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Form.Item>
                <Form.Item>
                  <Row gutter={[0, 20]}>
                    <Col xs={{ span: 24 }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                        loading={submitting}
                        disabled={submitting}
                      >
                        {t("auth.signIn.signInBtn")}
                      </Button>
                    </Col>

                    <Col xs={{ span: 24 }}>
                      <Button
                        onClick={handleSignInWithGoogle}
                        icon={<img src={GoogleLogo} width={18} alt="Google Logo" />}
                        style={{ width: "100%" }}
                      >
                        {t("auth.signIn.signInWithGoogle")}
                      </Button>
                    </Col>

                    <Col xs={{ span: 24 }}>
                      <ForgotPassword />
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Content>
      </Layout>
    </SignInFormContainer>
  );
};

export default SignInForm;
