import React from "react";
import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/client";
import { GET_SPONSORS_LIST } from "../../../graphql/queries";

import { Layout, Row, Col, Typography } from "antd";

import { Loading } from "../../Loading";

const { Content } = Layout;
const { Title } = Typography;

const Sponsors = () => {
  const { t } = useTranslation();

  const { loading: sponsorsListLoading, data: sponsorsListData } = useQuery(GET_SPONSORS_LIST, {
    variables: { active: true },
  });

  if (sponsorsListLoading) return <Loading />;

  return (
    <Layout
      id="our-sponsors"
      className="layout-default"
      style={{
        background: "#fff",
      }}
    >
      <Content
        style={{
          marginTop: "60px",
          marginBottom: "60px",
        }}
      >
        <Row gutter={[24, 40]} justify="space-around">
          <Col
            style={{
              width: "500px",
              textAlign: "center",
            }}
          >
            {/* header */}
            <Title
              className="mb-4 mt-0"
              style={{
                fontSize: "45px",
                fontWeight: "bolder",
              }}
            >
              {t("home.sponsors.title")}
            </Title>
          </Col>
        </Row>

        {/* sponsors list */}
        {!sponsorsListLoading && (
          <Row gutter={[24, 80]} justify="space-around" align="middle" className="mt-5">
            {sponsorsListData.sponsorsList.map((obj) => (
              <Col key={obj.id} md={8} xs={24} style={{ textAlign: "center" }}>
                <img
                  src={obj.logo}
                  alt={obj.name}
                  style={{
                    height: "120px",
                    objectFit: "contain",
                  }}
                />
              </Col>
            ))}
          </Row>
        )}
      </Content>
    </Layout>
  );
};

export default Sponsors;
