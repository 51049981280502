import { Modal, Descriptions } from "antd";

import { useTranslation } from "react-i18next";

import { REQUEST_DETAILS } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";

import { Loading } from "components/Loading";

import ModalStyles from "./styles";

import { formatTime } from "helpers/common";

const JobOpportunitiesModal = ({ isModalOpen, handleOk, handleCancel, requestID }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const { loading: requestDetailsDataLoading, data: requestDetailsData } = useQuery(
    REQUEST_DETAILS,
    { variables: { id: requestID } },
  );

  const getItems = (obj, index) => {
    return [
      {
        key: "jobTitle#" + index,
        label: t("requestForm.jobOpportunitiesForm.jobTitleField"),
        children: <span>{obj.jobTitle}</span>,
        span: 1,
      },
      {
        key: "jobDescription#" + index,
        label: t("requestForm.jobOpportunitiesForm.jobDescriptionField"),
        children: <span>{obj.jobDescription}</span>,
        span: 2,
      },
      {
        key: "availableOpportunitiesNumber#" + index,
        label: t("requestForm.jobOpportunitiesForm.availableOpportunitiesNumberTitle"),
        children: (
          <ul>
            <li>
              <b>{t("requestForm.jobOpportunitiesForm.malesNumberField")}: </b>
              {obj.malesNumber}
            </li>

            <li>
              <b>{t("requestForm.jobOpportunitiesForm.femalesNumberField")}: </b>
              {obj.femalesNumber}
            </li>
          </ul>
        ),
        span: 3,
      },
      {
        key: "workDays#" + index,
        label: t("requestForm.jobOpportunitiesForm.workDays"),
        children: <span>{obj.workDays}</span>,
        span: 1,
      },
      {
        key: "workingHours#" + index,
        label: t("requestForm.jobOpportunitiesForm.workingHours"),
        children: (
          <ul>
            <li>
              <b>{t("requestForm.jobOpportunitiesForm.startingTimeField")}: </b>
              {formatTime(obj.startingTime, currentLanguage)}
            </li>

            <li>
              <b>{t("requestForm.jobOpportunitiesForm.endingTimeField")}: </b>
              {formatTime(obj.endingTime, currentLanguage)}
            </li>
          </ul>
        ),
        span: 2,
      },
      {
        key: "locationUrl#" + index,
        label: t("requestForm.locationsForm.locationUrl"),
        children: (
          <a href={obj.location.url} target="_blank" rel="noopener noreferrer">
            {obj.location.url}
          </a>
        ),
        span: 1,
      },
      {
        key: "locationAddress#" + index,
        label: t("requestForm.locationsForm.locationAddress"),
        children: <span>{obj.location.address}</span>,
        span: 2,
      },
      {
        key: "specialNotes#" + index,
        label: t("requestForm.jobOpportunitiesForm.specialNotesField"),
        children: <span>{obj.specialNotes || "-"}</span>,
        span: 3,
      },
    ];
  };

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      title={t("requestForm.collapseHeader.jobOpportunities")}
      key={requestID}
      width={1000}
      footer={null}
    >
      {requestDetailsDataLoading ? (
        <Loading />
      ) : (
        <ModalStyles currentLanguage={currentLanguage}>
          {requestDetailsData?.requestDetails.jobOpportunities.map((obj, index) => (
            <Descriptions
              key={index}
              bordered
              layout="vertical"
              items={getItems(obj, index)}
              className="mb-4"
            />
          ))}
        </ModalStyles>
      )}
    </Modal>
  );
};

export default JobOpportunitiesModal;
