import { Button, Col, Form, Input, Row, Layout, Card, Typography, Result, Alert } from "antd";
import useAuth from "../../auth";
import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, Link } from "react-router-dom";
import AccountVerificationContainer from "./styles";
import { ACCOUNT_VERIFICATION_MUTATION, RESEND_OTP_CODE_MUTATION } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";

const { Content } = Layout;
const { Title, Text } = Typography;

const AccountVerificationForm = () => {
  const { user, signIn } = useAuth();

  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState(null);
  const errorMessageRef = useRef(null);

  const [submitting, setSubmitting] = useState(false);
  const [resendCodeBtnLoading, setResendCodeBtnLoading] = useState(false);

  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();
  const sendEmailOnUseEffect = location.state?.sendEmailOnUseEffect;

  useEffect(() => {
    if (sendEmailOnUseEffect) resendOtpCodeWithoutAlertMutation();
  }, [sendEmailOnUseEffect]);

  const [accountVerificationMutation] = useMutation(ACCOUNT_VERIFICATION_MUTATION, {
    onCompleted: (data) => {
      if (data.accountVerification.success) {
        setErrorMessage(null);
        if (data.accountVerification.token) signIn({ token: data.accountVerification.token });
        localStorage.setItem("emailVerifiedSuccessfullyMessage", "true");
        navigate(`/profile/${user.userID}`);
      } else {
        setErrorMessage(data.accountVerification.errors[0]);
        scrollToError();
        setSubmitting(false);
      }
    },
  });

  const [resendOtpCodeWithAlertMutation] = useMutation(RESEND_OTP_CODE_MUTATION, {
    onCompleted: (data) => {
      if (data.resendOtpCode.success) {
        setErrorMessage(null);
        setResendCodeBtnLoading(false);

        setShowAlert(true);

        // Automatically hide the alert after 5 seconds
        setTimeout(() => {
          setShowAlert(false);
        }, 5000);
      } else {
        setErrorMessage(data.resendOtpCode.errors[0]);
        scrollToError();
        setResendCodeBtnLoading(false);
      }
    },
  });

  const [resendOtpCodeWithoutAlertMutation] = useMutation(RESEND_OTP_CODE_MUTATION, {
    onCompleted: (data) => {
      if (data.resendOtpCode.success) {
        setErrorMessage(null);
        setResendCodeBtnLoading(false);
      } else {
        setErrorMessage(data.resendOtpCode.errors[0]);
        scrollToError();
        setResendCodeBtnLoading(false);
      }
    },
  });

  const onResendCodeBtnClick = () => {
    setResendCodeBtnLoading(true);
    resendOtpCodeWithAlertMutation();
  };

  const onFinish = (values) => {
    setSubmitting(true);
    accountVerificationMutation({
      variables: { ...values },
    });
  };

  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line no-console
    console.log("Failed:", errorInfo);
  };

  const scrollToError = () => {
    if (errorMessageRef.current) {
      errorMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  if (user.isVerified)
    return (
      <AccountVerificationContainer>
        <Result
          status="success"
          title={t("auth.accountVerification.emailVerifiedSuccessfullyMessage")}
        />
      </AccountVerificationContainer>
    );

  return (
    <AccountVerificationContainer>
      <Layout className="layout-default ant-layout layout-sign-up">
        <Content className="pt-0">
          <Title
            className="mb-3 mt-0"
            style={{
              fontSize: "45px",
              whiteSpace: "break-spaces",
              textAlign: "center",
            }}
          >
            {t("auth.accountVerification.verifyEmail")}
          </Title>

          <Text
            className="mb-3 mt-0"
            style={{
              fontSize: "16px",
              whiteSpace: "break-spaces",
              textAlign: "center",
              display: "block",
            }}
          >
            {t("auth.accountVerification.enterCodeMessage")}&nbsp;
            <span style={{ fontFamily: '"Rubik", sans-serif' }}>{user.email}</span>
          </Text>

          <Card className="card-signup header-solid h-full ant-card pt-0" bordered="false">
            <Form onFinish={onFinish} onFinishFailed={onFinishFailed} scrollToFirstError={true}>
              <div ref={errorMessageRef}></div> {/* Error message ref */}
              {errorMessage && <p className="text-danger english-field">{errorMessage}</p>}
              {showAlert && (
                <Row gutter={[24, 40]} className="mb-3">
                  <Col xs={24}>
                    <Alert
                      message={
                        <>
                          {t("auth.accountVerification.emailSentSuccessfullyMessage")}
                          <span style={{ fontFamily: '"Rubik", sans-serif' }}>{user.email}</span>
                        </>
                      }
                      type="success"
                      closable
                      onClose={() => setShowAlert(false)} // Close the alert when clicked
                    />
                  </Col>
                </Row>
              )}
              <Row gutter={[24, 0]}>
                <Col xs={24}>
                  <Form.Item
                    name="otpCode"
                    rules={[
                      {
                        required: true,
                        message: t("auth.signUp.requiredFieldMessage"),
                      },
                      {
                        min: 6,
                        message: t("auth.accountVerification.codeLengthMessage"),
                      },
                      {
                        max: 6,
                        message: t("auth.accountVerification.codeLengthMessage"),
                      },
                    ]}
                  >
                    <Input showCount className="english-field" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24, 0]}>
                <Col xs={24}>
                  <Form.Item className="text-center">
                    <Button
                      style={{ width: "60%" }}
                      type="primary"
                      htmlType="submit"
                      loading={submitting}
                      disabled={submitting}
                    >
                      {t("auth.accountVerification.verifyAccountBtn")}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24, 0]}>
                <Col xs={24}>
                  <Form.Item className="text-center">
                    <Button
                      style={{ width: "60%" }}
                      type="secondary"
                      disabled={resendCodeBtnLoading}
                      loading={resendCodeBtnLoading}
                      onClick={onResendCodeBtnClick}
                    >
                      {t("auth.accountVerification.resendCodeBtn")}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>

          <Text
            style={{
              fontSize: "16px",
              whiteSpace: "break-spaces",
              textAlign: "center",
              display: "block",
            }}
          >
            {t("auth.accountVerification.updateEmailAddressPrompt")}

            <Link to={`/profile/${user.userID}`} className="font-bold" style={{ color: "#35b4a7" }}>
              {t("auth.accountVerification.updateEmailAddressLink")}
            </Link>
          </Text>
        </Content>
      </Layout>
    </AccountVerificationContainer>
  );
};

export default AccountVerificationForm;
