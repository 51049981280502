import { gql } from "@apollo/client";

export const PARTICIPANT_PROFILE = gql`
  fragment ParticipantProfileFields on ParticipantProfileType {
    id
    participantId
    fullNameEn
    fullNameAr
    civilIdNumber
    mobileNumber
    age
    gender
    dateOfBirth
    user {
      email
    }
  }
`;

export const GET_REGISTRATIONS = gql`
  ${PARTICIPANT_PROFILE}
  query registrations($filters: String, $search: String, $first: Int, $skip: Int) {
    registrations(filters: $filters, search: $search, first: $first, skip: $skip) {
      id
      status
      createdAt
      updatedAt
      paymentStatus
      valuesYouBelieveIn
      howDidYouKnowAboutTheProgram
      mediaSkillsYesNo
      mediaSkills
      mediaFields
      civilId
      personalImage
      ibanNumber
      specialRequest
      programRequirements
      correctInformation
      formType
      assignedJobOpportunity {
        jobTitle
        companyRequest {
          companyNameEn
          companyNameAr
        }
      }
      participantProfile {
        ...ParticipantProfileFields
      }
    }
  }
`;
