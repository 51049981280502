import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Col, Flex, Row, Typography } from "antd";

import SearchBar from "components/inputs/SearchBar";

import { REQUESTS_LIST } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";

import RequestsTable from "./RequestsTable";

import useAuth from "../../../auth";

const { Title } = Typography;

const RequestsDashboard = () => {
  const { t } = useTranslation();

  const { user } = useAuth();

  const [searchQuery, setSearchQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);

  const { loading: requestsListDataLoading, data: requestsListData } = useQuery(REQUESTS_LIST, {
    variables: { search: searchQuery, first: 50, offset: (pageNumber - 1) * 50 },
  });

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Flex
      vertical
      gap="middle"
      style={{
        backgroundColor: "white",
        padding: 24,
      }}
    >
      {user && user.userType === "Staff" && (
        <Row className="searchbar-row">
          <Col xs={24} lg={10}>
            <SearchBar
              placeholder={t("dashboard.requestsTable.searchPlaceholder")}
              value={searchQuery}
              onChange={handleChange}
            />
          </Col>
        </Row>
      )}

      {user && user.userType === "Company" && (
        <Row>
          <Col xs={24} lg={10}>
            <Title level={3}>{t("dashboard.requestsTable.myRequestsTitle")}</Title>
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={24}>
          <RequestsTable
            totalCount={requestsListData?.requestsList?.totalCount || 0}
            data={requestsListData?.requestsList?.edges || []}
            loading={requestsListDataLoading}
            setPageNumber={setPageNumber}
          />
        </Col>
      </Row>
    </Flex>
  );
};

export default RequestsDashboard;
