import { useState } from "react";

import { Modal, Form, Select, Button } from "antd";

import { useTranslation } from "react-i18next";

import { AVAILABLE_COMPANIES_LIST } from "graphql/queries";
import { ASSIGN_TO_COMPANY_MUTATION } from "graphql/mutations";
import { useQuery, useMutation } from "@apollo/client";

import { Loading } from "components/Loading";

import { formatTime } from "helpers/common";

const { Option } = Select;

const AssignToCompanyModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  registrationID,
  gender,
  refetchData,
}) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const { loading: availableCompaniesListLoading, data: availableCompaniesListData } = useQuery(
    AVAILABLE_COMPANIES_LIST,
    { variables: { gender } },
  );

  const [company, setCompany] = useState(null);

  const [successMessage, setSuccessMessage] = useState(null);

  const [assignToCompanyMutation] = useMutation(ASSIGN_TO_COMPANY_MUTATION, {
    onCompleted: () => {
      refetchData();
      setSuccessMessage(t("dashboard.assignToCompanyModal.savedSuccessfullyMessage"));
    },
  });

  const onFinish = (values) => {
    assignToCompanyMutation({
      variables: {
        registrationId: registrationID,
        assignedJobOpportunity: values.assignedJobOpportunity,
      },
    });
  };

  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line no-console
    console.log("Failed:", errorInfo);
  };

  return (
    <Modal
      title={t("dashboard.assignToCompanyModal.modalTitle")}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      key={registrationID}
      width={700}
      footer={null}
    >
      {availableCompaniesListLoading ? (
        <Loading />
      ) : availableCompaniesListData.availableCompaniesList === null ||
        availableCompaniesListData.availableCompaniesList.length === 0 ? (
        <p className="text-danger mb-0">
          {t("dashboard.assignToCompanyModal.noAvailableCompanies")}
        </p>
      ) : (
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="row-col"
          scrollToFirstError={true}
        >
          {successMessage && <p className="text-success">{successMessage}</p>}

          <Form.Item
            label={t("dashboard.assignToCompanyModal.assignedCompanyNameField")}
            name="company"
            rules={[
              {
                required: true,
                message: t("dashboard.assignToCompanyModal.requiredFieldMessage"),
              },
            ]}
            initialValue=""
          >
            <Select
              onChange={(value) =>
                setCompany(availableCompaniesListData.availableCompaniesList[value])
              }
            >
              <Option key="" value="">
                -----
              </Option>
              ,
              {availableCompaniesListData.availableCompaniesList.map((obj, index) => (
                <Option
                  key={obj.id}
                  value={index}
                  disabled={obj.status !== "Available"}
                  style={{ textDecoration: obj.status !== "Available" ? "line-through" : "none" }}
                >
                  {currentLanguage === "en" ? obj.companyNameEn : obj.companyNameAr}
                  {obj.status === "Available"
                    ? null
                    : ` (${t(`dashboard.assignToCompanyModal.${obj.status}`)})`}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {company && (
            <Form.Item
              label={t("dashboard.assignToCompanyModal.assignedJobTitleField")}
              name="assignedJobOpportunity"
              rules={[
                {
                  required: true,
                  message: t("dashboard.assignToCompanyModal.requiredFieldMessage"),
                },
              ]}
              initialValue=""
            >
              <Select>
                <Option key="" value="">
                  -----
                </Option>
                ,
                {company.jobOpportunities.map((obj) => (
                  <Option
                    key={obj.id}
                    value={obj.id}
                    disabled={obj.status !== "Available"}
                    style={{ textDecoration: obj.status !== "Available" ? "line-through" : "none" }}
                  >
                    {obj.status === "Available"
                      ? `${obj.jobTitle} (${formatTime(obj.startingTime, currentLanguage)} -
      ${formatTime(obj.endingTime, currentLanguage)}) - ${obj.location.address}`
                      : `${obj.jobTitle} (${t(`dashboard.assignToCompanyModal.${obj.status}`)})`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              {t("dashboard.assignToCompanyModal.saveBtn")}
            </Button>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default AssignToCompanyModal;
