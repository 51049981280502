import { useQuery } from "@apollo/client";
import { Col, Divider, Menu, Row, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaSnapchatGhost,
  FaTiktok,
  FaTwitter,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { GET_FOOTER_SECTION_DETAILS, GET_SOCIAL_MEDIA_LIST } from "../../graphql/queries";
import FooterContainer from "./styles";

const { Title, Text } = Typography;

const Footer = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const { loading: footerSectionDetailsLoading, data: footerSectionDetailsData } = useQuery(
    GET_FOOTER_SECTION_DETAILS,
    {},
  );

  const { loading: socialMediaListLoading, data: socialMediaListData } = useQuery(
    GET_SOCIAL_MEDIA_LIST,
    {
      variables: { active: true },
    },
  );

  if (footerSectionDetailsLoading) return null;

  const iconsMap = {
    Instagram: <FaInstagram />,
    Linkedin: <FaLinkedinIn />,
    Twitter: <FaTwitter />,
    Tiktok: <FaTiktok />,
    Facebook: <FaFacebookF />,
    Snapchat: <FaSnapchatGhost />,
  };

  const companyMenuItems = [
    {
      label: t("footer.companyMenu.company"),
      key: "company",
      disabled: true,
    },
    {
      label: <HashLink to="/#our-story">{t("footer.companyMenu.aboutUs")}</HashLink>,
      key: "about_us",
    },
    {
      label: <HashLink to="/#our-programs">{t("footer.common.programs")}</HashLink>,
      key: "programs",
    },
    {
      label: <HashLink to="/#our-team">{t("footer.companyMenu.team")}</HashLink>,
      key: "team",
    },
    {
      label: <HashLink to="/#contact-us">{t("footer.common.contactUs")}</HashLink>,
      key: "contact_us",
    },
  ];

  const enterpriseMenuItems = [
    {
      label: t("footer.enterpriseMenu.enterprise"),
      key: "enterprise",
      disabled: true,
    },
    {
      label: <Link to="/user-type">{t("footer.common.joinUs")}</Link>,
      key: "join_us",
    },
    {
      label: <HashLink to="/#our-sponsors">{t("footer.enterpriseMenu.sponsor")}</HashLink>,
      key: "sponsor",
    },
    {
      label: <HashLink to="/#our-partners">{t("footer.enterpriseMenu.partner")}</HashLink>,
      key: "partner",
    },
    {
      label: <HashLink to="/#contact-us">{t("footer.common.contactUs")}</HashLink>,
      key: "contact_us",
    },
  ];

  const individualMenuItems = [
    {
      label: t("footer.individualMenu.individual"),
      key: "individual",
      disabled: true,
    },
    {
      label: <Link to="/user-type">{t("footer.common.joinUs")}</Link>,
      key: "join_us",
    },
    {
      label: <HashLink to="/#our-programs">{t("footer.common.programs")}</HashLink>,
      key: "programs",
    },
    {
      label: <HashLink to="/#contact-us">{t("footer.common.contactUs")}</HashLink>,
      key: "contact_us",
    },
  ];

  const contactUsMenuItems = [
    {
      label: <a href="#contact-us">{t("footer.common.contactUs")}</a>,
      key: "contact_us",
      disabled: true,
    },
    {
      label: (
        <a
          href={`mailto:${footerSectionDetailsData.footerSectionDetails.email}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {footerSectionDetailsData.footerSectionDetails.email}
        </a>
      ),
      key: "email",
    },
    {
      label: (
        <a
          href={`https://wa.me/${footerSectionDetailsData.footerSectionDetails.whatsappNumber}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span style={{ unicodeBidi: "embed", direction: "ltr" }}>
            {footerSectionDetailsData.footerSectionDetails.whatsappNumber}
          </span>
        </a>
      ),
      key: "whatsapp_number",
    },
  ];

  return (
    <FooterContainer>
      <Row gutter={[24, 40]} justify="space-around">
        <Col
          lg={12}
          xs={24}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Link to="/" style={{ textDecoration: "none" }}>
            {/* title */}
            <Title
              className="mb-4 mt-0 text-white"
              style={{
                fontSize: "45px",
              }}
            >
              {t("footer.title")}
            </Title>
          </Link>
          {/* brief */}
          <Text
            className="text-white"
            style={{
              fontSize: "16px",
            }}
          >
            {
              footerSectionDetailsData.footerSectionDetails[
                "brief" + (currentLanguage === "en" ? "En" : "Ar")
              ]
            }
          </Text>
        </Col>

        {/* Company Menu */}
        <Col lg={3} md={6} xs={12}>
          <Menu mode="vertical" theme="dark" items={companyMenuItems} />
        </Col>

        {/* Enterprise Menu */}
        <Col lg={3} md={6} xs={12}>
          <Menu mode="vertical" theme="dark" items={enterpriseMenuItems} />
        </Col>

        {/* Individual Menu */}
        <Col lg={3} md={6} xs={12}>
          <Menu mode="vertical" theme="dark" items={individualMenuItems} />
        </Col>

        {/* Contact Us Menu */}
        <Col lg={3} md={6} xs={12} className="p-0">
          <Menu mode="vertical" theme="dark" items={contactUsMenuItems} />
        </Col>
      </Row>

      <Divider
        style={{
          borderColor: "rgba(255, 255, 255, 0.5)",
          borderWidth: "2px",
          paddingBottom: "48px",
        }}
      />

      <Row gutter={[24, 40]} justify="space-around">
        <Col md={12} xs={24}>
          <p
            className="copyright"
            style={
              currentLanguage === "ar"
                ? {
                    direction: "ltr",
                    textAlign: "right",
                  }
                : {}
            }
          >
            © {moment().year()} Copyright, All Right Reserved, Made by&nbsp;
            <a href="https://www.instagram.com/devloykw" target="_blank" rel="noopener noreferrer">
              Devloy.
            </a>
          </p>
        </Col>

        <Col
          md={12}
          xs={24}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {!socialMediaListLoading &&
            socialMediaListData?.socialMediaList?.map((obj) => {
              const IconComponent = iconsMap[obj.type];
              return (
                <a key={obj.id} href={obj.url} target="_blank" rel="noopener noreferrer">
                  {React.cloneElement(IconComponent, {
                    className: "icon",
                  })}
                </a>
              );
            })}
        </Col>
      </Row>
    </FooterContainer>
  );
};

export default Footer;
