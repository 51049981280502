import { Col, Form, Input, Row, Select, Checkbox } from "antd";

import { useState } from "react";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const StepTwo = ({
  civilId,
  setCivilId,
  personalImage,
  setPersonalImage,
  ibanNumber,
  setIbanNumber,
  formType,
  setMediaFields,
}) => {
  const { t } = useTranslation();

  const [mediaSkillsYesNo, setMediaSkillsYesNo] = useState(null);

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24}>
          <Form.Item
            label={t("registrationForm.stepTwo.valuesYouBelieveInField")}
            name="valuesYouBelieveIn"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col xs={24}>
          <Form.Item
            label={t("registrationForm.stepTwo.howDidYouKnowAboutTheProgramField")}
            name="howDidYouKnowAboutTheProgram"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      {formType === "Seasonal Form" && (
        <>
          <Row gutter={[24, 0]}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t("registrationForm.stepTwo.mediaSkillsYesNoField")}
                name="mediaSkillsYesNo"
                rules={[
                  {
                    required: true,
                    message: t("auth.signUp.requiredFieldMessage"),
                  },
                ]}
                initialValue=""
              >
                <Select onChange={(value) => setMediaSkillsYesNo(value)}>
                  <Option key="" value="">
                    -----
                  </Option>
                  ,
                  <Option key="Yes" value="Yes">
                    {t("auth.signUp.yesOption")}
                  </Option>
                  ,
                  <Option key="No" value="No">
                    {t("auth.signUp.noOption")}
                  </Option>
                  ,
                </Select>
              </Form.Item>
            </Col>

            {mediaSkillsYesNo === "Yes" && (
              <Col xs={24} lg={12}>
                <Form.Item
                  label={t("registrationForm.stepTwo.mediaSkillsField")}
                  name="mediaSkills"
                  rules={[
                    {
                      required: true,
                      message: t("auth.signUp.requiredFieldMessage"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row gutter={[24, 0]}>
            <Col xs={24}>
              <Form.Item
                label={t("registrationForm.stepTwo.mediaFieldsField")}
                name="mediaFields"
                rules={[
                  {
                    required: true,
                    message: t("auth.signUp.requiredFieldMessage"),
                  },
                ]}
              >
                <Checkbox.Group
                  style={{ width: "100%" }}
                  onChange={(checkedValues) => {
                    setMediaFields(checkedValues);
                  }}
                >
                  <Row className="mb-1 mx-0">
                    <Col>
                      <Checkbox value="Podcast">
                        {t("registrationForm.stepTwo.podcastOption")}
                      </Checkbox>
                    </Col>
                  </Row>

                  <Row className="mb-1 mx-0">
                    <Col>
                      <Checkbox value="Scriptwriting">
                        {t("registrationForm.stepTwo.scriptwritingOption")}
                      </Checkbox>
                    </Col>
                  </Row>

                  <Row className="mb-1 mx-0">
                    <Col>
                      <Checkbox value="Reels">{t("registrationForm.stepTwo.reelsOption")}</Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      <Row gutter={[24, 0]}>
        <Col xs={24} lg={12}>
          <Form.Item
            label={
              <>
                {t("registrationForm.stepTwo.civilIdField")}
                &nbsp;
                <span className="english-field">(.PNG, .JPEG, .JPG)</span>
              </>
            }
            name="civilId"
            help={t("registrationForm.stepTwo.helpText.fileSize")}
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!civilId)
                    return Promise.reject(new Error(t("auth.signUp.requiredFieldMessage")));

                  if (civilId.size > 1024 * 1024)
                    return Promise.reject(
                      new Error(t("registrationForm.stepTwo.helpText.fileSizeError")),
                    );

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <input
              type="file"
              accept="image/*"
              onChange={(event) => {
                setCivilId(event.target.files[0]);
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} lg={12}>
          <Form.Item
            label={
              <>
                {t("registrationForm.stepTwo.personalImageField")}
                &nbsp;
                <span className="english-field">(.PNG, .JPEG, .JPG)</span>
              </>
            }
            help={t("registrationForm.stepTwo.helpText.fileSize")}
            name="personalImage"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!personalImage)
                    return Promise.reject(new Error(t("auth.signUp.requiredFieldMessage")));

                  if (personalImage.size > 1024 * 1024)
                    return Promise.reject(
                      new Error(t("registrationForm.stepTwo.helpText.fileSizeError")),
                    );

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <input
              type="file"
              accept="image/*"
              onChange={(event) => {
                setPersonalImage(event.target.files[0]);
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col xs={24}>
          <Form.Item
            label={t("registrationForm.stepTwo.ibanNumberField")}
            help={
              <>
                <small>{t("registrationForm.stepTwo.helpText.onlyPDF")}</small>
                <br />
                <small>{t("registrationForm.stepTwo.helpText.fileSize")}</small>
                <br />
                <small>{t("registrationForm.stepTwo.helpText.parentAccount")}</small>
                <br />
                <small>{t("registrationForm.stepTwo.helpText.bankCard")}</small>
              </>
            }
            name="ibanNumber"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!ibanNumber)
                    return Promise.reject(new Error(t("auth.signUp.requiredFieldMessage")));

                  if (ibanNumber.size > 1024 * 1024)
                    return Promise.reject(
                      new Error(t("registrationForm.stepTwo.helpText.fileSizeError")),
                    );

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <input
              type="file"
              accept="application/pdf"
              onChange={(event) => {
                setIbanNumber(event.target.files[0]);
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col xs={24}>
          <Form.Item
            label={t("registrationForm.stepTwo.specialRequestField")}
            name="specialRequest"
          >
            <Input.TextArea rows={5} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
export default StepTwo;
