import styled from "styled-components";

const UserTypeFormContainer = styled.div`
  padding-bottom: 50px;

  .ant-card:hover {
    border: 2px solid #35b4a7;
  }

  .ant-layout {
    background: transparent;
    margin: 0;
  }
`;

export default UserTypeFormContainer;
