import styled from "styled-components";

const RegistrationFormContainer = styled.div`
  padding-bottom: 40px;

  .ant-layout {
    background: transparent;
    margin: 0;
  }

  .ant-card-body {
    padding: 0px 20px;
  }

  .ant-steps-item-content {
    font-weight: bold;
  }

  .react-tel-input .form-control {
    border-radius: 6px;
    width: 100%;
    height: 40px;
    color: #000;
    font-size: 14px;
  }
`;

export default RegistrationFormContainer;
