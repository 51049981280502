import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button, Form, Row, Col, Radio, Card, Space } from "antd";

import { NEW_TRANSACTION_MUTATION } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";

const { Meta } = Card;

const PaymentMethodForm = ({ paymentMethodsList }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const { registrationID } = useParams();

  const [errorMessage, setErrorMessage] = useState(null);
  const errorMessageRef = useRef(null);

  const [submitting, setSubmitting] = useState(false);

  const [newTransactionMutation] = useMutation(NEW_TRANSACTION_MUTATION, {
    onCompleted: (data) => {
      if (data.newTransaction.success) {
        setErrorMessage(null);
        window.location.href = data.newTransaction.paymentUrl;
      } else {
        setErrorMessage(data.newTransaction.errors[0]);
        scrollToError();
        setSubmitting(false);
      }
    },
  });

  const onFinish = (values) => {
    setSubmitting(true);

    newTransactionMutation({
      variables: {
        paymentMethodId: values.payment_method,
        registrationId: parseInt(registrationID),
      },
    });
  };

  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line no-console
    console.log("Failed:", errorInfo);
  };

  const scrollToError = () => {
    if (errorMessageRef.current) {
      errorMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <Row
      className="mx-3 my-5"
      style={{
        justifyContent: "center",
      }}
    >
      <Col
        xs={24}
        lg={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <Card bordered={false} style={{ width: "100%" }}>
          <Meta
            title={t("payment.paymentMethodForm.formTitle")}
            description={
              <>
                {t("payment.paymentMethodForm.formDescription")}
                <span className="text-danger">
                  &nbsp;{paymentMethodsList[0].TotalAmount}&nbsp;
                  {t("payment.paymentMethodForm.currency")}
                </span>
              </>
            }
          />

          <Form onFinish={onFinish} onFinishFailed={onFinishFailed} scrollToFirstError={true}>
            <div ref={errorMessageRef} className="mt-3"></div> {/* Error message ref */}
            {errorMessage && <p className="text-danger english-field">{errorMessage}</p>}
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <Form.Item
                  name="payment_method"
                  rules={[
                    {
                      required: true,
                      message: t("payment.paymentMethodForm.requiredFieldMessage"),
                    },
                  ]}
                >
                  <Radio.Group>
                    <Space direction="vertical" className="mt-3">
                      {paymentMethodsList.map((obj) => (
                        <Radio
                          key={obj.PaymentMethodId}
                          value={obj.PaymentMethodId}
                          className="mb-1"
                        >
                          <img src={obj.ImageUrl} alt="" width="45px" className="mx-3"></img>
                          {currentLanguage === "en" ? obj.PaymentMethodEn : obj.PaymentMethodAr}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <Form.Item className="text-center">
                  <Button
                    style={{ width: "60%" }}
                    type="primary"
                    htmlType="submit"
                    loading={submitting}
                    disabled={submitting}
                  >
                    {t("payment.paymentMethodForm.proceedToPaymentBtn")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default PaymentMethodForm;
