import { Card, Flex, Typography } from "antd";
import { useState } from "react";
import styled from "styled-components";

const { Text, Paragraph } = Typography;

type Props = {
  title: string;
  logo: string;
  description: string;
};

const CompanyCard = (props: Props) => {
  const [showDescription, setShowDescription] = useState(false);

  return (
    <StyledCard
      style={{
        minHeight: "100%",
      }}
      onMouseEnter={() => setShowDescription(true)}
      onMouseLeave={() => setShowDescription(false)}
    >
      <Flex vertical justify="center" gap={24}>
        {showDescription ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              alignSelf: "center",
              justifyContent: "center",
            }}
          >
            <Paragraph
              style={{
                fontSize: "14px",
              }}
              ellipsis={{ rows: 3, expandable: false, symbol: "..." }}
            >
              {props.description}
            </Paragraph>
          </div>
        ) : (
          <>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <img
                src={props.logo}
                alt={props.title}
                height="160"
                style={{ display: "flex", objectFit: "contain" }}
              />
            </div>

            <Text
              className="d-flex justify-content-center"
              style={{ fontSize: "20px", textAlign: "center" }}
            >
              {props.title}
            </Text>
          </>
        )}
      </Flex>
    </StyledCard>
  );
};

export default CompanyCard;

const StyledCard = styled(Card)`
  height: 265px;

  .ant-card-body,
  .ant-flex {
    height: 100%;
  }

  &:hover {
    background-color: rgb(45, 165, 149);
    .ant-typography {
      color: white !important;
    }
  }

  .ant-typography {
    color: "rgb(87,91,103)" !important;
    text-align: center;
  }
`;
