import { useQuery } from "@apollo/client";
import { Col, Row, Typography, Button } from "antd";
import { Content } from "antd/es/layout/layout";
import { Loading } from "components/Loading";
import { GET_OUR_PROGRAMS_YEARS, GET_OUR_PROGRAM_SECTION_DETAILS } from "graphql/queries";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { GetAboutData, GetOurProgramSectionDetail, GetOurProgramYears } from "types/Home";

import CompanyCard from "./CompanyCard";
import YearSwitcher from "./YearSwitcher";

const { Title, Text } = Typography;

type OurProgramsSectionProps = {
  aboutData: GetAboutData | undefined;
  aboutDataLoading: boolean;
};

const OurProgramsSection = ({ aboutData, aboutDataLoading }: OurProgramsSectionProps) => {
  const [selectedYear, setSelectedYear] = useState<string | null>(null);
  const [viewAll, setViewAll] = useState<boolean | false>(false);

  const { data: programYearsData, loading: programYearsLoading } =
    useQuery<GetOurProgramYears>(GET_OUR_PROGRAMS_YEARS);

  const { data: ourProgramSectionDetailsData, loading: ourProgramSectionDetailsLoading } =
    useQuery<GetOurProgramSectionDetail>(GET_OUR_PROGRAM_SECTION_DETAILS, {
      variables: selectedYear
        ? {
            year: selectedYear,
          }
        : {},
    });

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const isArabic = language === "ar";

  const loading = aboutDataLoading || programYearsLoading || ourProgramSectionDetailsLoading;

  const { aboutSectionDetails } = aboutData || {};
  const { ourProgramsDetails } = ourProgramSectionDetailsData || {};

  const { ourProgramYears } = programYearsData || {};

  const handleChangeActiveYear = (year: string) => {
    setSelectedYear(year);
  };

  if (loading || aboutDataLoading) return <Loading />;

  return (
    <Content
      id="our-programs"
      style={{
        marginTop: "60px",
        paddingBottom: "60px",
        padding: "60px 48px",
        backgroundColor: "rgb(242, 245, 249)",
      }}
    >
      <Row
        gutter={[24, 40]}
        justify="space-around"
        style={{
          marginBottom: "60px",
        }}
      >
        <Col
          style={{
            maxWidth: "700px",
            textAlign: "justify",
            textAlignLast: "center",
          }}
        >
          <Title
            className="mb-4 mt-0"
            style={{
              fontSize: "45px",
              fontWeight: "bolder",
            }}
          >
            {t("home.ourPrograms.title")}
          </Title>

          <Text
            style={{
              fontSize: "16px",
              color: "rgb(87,91,103)",
            }}
          >
            {isArabic ? aboutSectionDetails?.ourProgramAr : aboutSectionDetails?.ourProgramEn}
          </Text>
        </Col>
      </Row>

      {ourProgramYears && ourProgramYears?.length > 1 && (
        <Row gutter={[24, 40]} justify="center">
          <YearSwitcher
            years={ourProgramYears || []}
            currentActiveYear={selectedYear ?? ourProgramYears?.[0] ?? null}
            handleChangeActiveYear={handleChangeActiveYear}
          />
        </Row>
      )}

      <Row gutter={[24, 40]} justify="space-around">
        {viewAll
          ? ourProgramsDetails?.map((program) => {
              return (
                <Col key={program.id} xs={24} md={16} lg={8}>
                  <CompanyCard
                    title={isArabic ? program.company.nameAr : program.company.nameEn}
                    logo={program.company.logo}
                    description={
                      isArabic ? program.company.descriptionAr : program.company.descriptionEn
                    }
                  />
                </Col>
              );
            })
          : ourProgramsDetails?.slice(0, 6).map((program) => {
              return (
                <Col key={program.id} xs={24} md={16} lg={8}>
                  <CompanyCard
                    title={isArabic ? program.company.nameAr : program.company.nameEn}
                    logo={program.company.logo}
                    description={
                      isArabic ? program.company.descriptionAr : program.company.descriptionEn
                    }
                  />
                </Col>
              );
            })}
      </Row>

      <Row justify="center" align="middle" className="mt-5">
        <Col>
          <Button type="primary" onClick={() => setViewAll(!viewAll)}>
            {viewAll ? t("home.ourPrograms.hideButton") : t("home.ourPrograms.viewAllButton")}
          </Button>
        </Col>
      </Row>
    </Content>
  );
};

export default OurProgramsSection;
