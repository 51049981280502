import moment from "moment";
import axios from "axios";

export const formatTime = (timeString, language) => {
  const momentTime = moment(timeString, "HH:mm:ss");
  if (language === "ar" && momentTime.format("A") === "AM")
    return momentTime.format("hh:mm صباحاً");
  else if (language === "ar" && momentTime.format("A") === "PM")
    return momentTime.format("hh:mm مساءً");
  else return momentTime.format("hh:mm A");
};

export const getUserLocation = () => {
  return new Promise((resolve, reject) => {
    // Check if geolocation is supported by the user's browser
    if (navigator.geolocation) {
      // Get the current user's location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Save the geolocation coordinates in two variables
          const { latitude, longitude } = position.coords;
          resolve({ latitude, longitude, error: null });
        },
        // If there was an error getting the user's location
        (error) => {
          let errorMessage;
          switch (error.code) {
            case error.PERMISSION_DENIED:
              errorMessage = "Error | User denied the request for Geolocation.";
              break;
            case error.POSITION_UNAVAILABLE:
              errorMessage = "Error | Location information is unavailable.";
              break;
            case error.TIMEOUT:
              errorMessage = "Error | The request to get user location timed out.";
              break;
            case error.UNKNOWN_ERROR:
            default:
              errorMessage = "Error | An unknown error occurred.";
              break;
          }
          reject({ latitude: null, longitude: null, error: errorMessage });
        },
      );
    } else {
      reject({
        latitude: null,
        longitude: null,
        error: "Error | Geolocation is not supported.",
      });
    }
  });
};

export const getFileSrc = async (fileURL) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}get_typeform_file/`, {
      params: {
        file_url: fileURL,
      },
      responseType: "blob",
    });

    const reader = new FileReader();
    reader.readAsDataURL(response.data);

    return new Promise((resolve, reject) => {
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error fetching image:", error);
    throw error;
  }
};
