import { useQuery } from "@apollo/client";
import { Col, Result, Row, Typography, Alert } from "antd";
import { Loading } from "components/Loading";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  PARTICIPANT_HISTORY_DETAILS,
  PARTICIPANT_PROFILE_INFORMATION,
} from "../../../../graphql/queries";
import ApplyNowCard from "../ApplyNowCard";
import ParticipantProfileContainer from "../styles";
import HistoryCard from "./HistoryCard";
import { useState, useEffect } from "react";

const { Title } = Typography;

const ParticipantHistory = () => {
  const { userID } = useParams();

  const { t } = useTranslation();

  const [showAlert, setShowAlert] = useState(false);
  const [showFullyBookedMessage, setShowFullyBookedMessage] = useState(false);

  useEffect(() => {
    const registeredSuccessfullyMessage = localStorage.getItem("registeredSuccessfullyMessage");
    const showFullyBookedMessage = localStorage.getItem("showFullyBookedMessage");

    if (registeredSuccessfullyMessage === "true") {
      setShowAlert(true);
      if (showFullyBookedMessage === "true") setShowFullyBookedMessage(true);

      localStorage.removeItem("registeredSuccessfullyMessage");
      localStorage.removeItem("showFullyBookedMessage");

      // Automatically hide the alert after 5 seconds
      setTimeout(() => {
        setShowAlert(false);
        setShowFullyBookedMessage(false);
      }, 5000);
    }
  }, []);

  const {
    loading: participantProfileDataLoading,
    data: participantProfileData,
    error: participantProfileDataError,
  } = useQuery(PARTICIPANT_PROFILE_INFORMATION, {
    variables: { userId: parseInt(userID) },
  });

  const {
    loading: participantHistoryDataLoading,
    data: participantHistoryData,
    refetch: participantHistoryDataRefetch,
    error: participantHistoryDataError,
  } = useQuery(PARTICIPANT_HISTORY_DETAILS, {
    variables: { userId: parseInt(userID) },
    fetchPolicy: "no-cache",
  });

  if (participantHistoryDataLoading || participantProfileDataLoading) return <Loading />;

  if (participantProfileDataError)
    return (
      <ParticipantProfileContainer>
        <Result
          status="error"
          title={t("auth.participantProfile.errorFetchingDataMessage")}
          subTitle={participantProfileDataError.message}
        />
      </ParticipantProfileContainer>
    );

  if (participantHistoryDataError)
    return (
      <ParticipantProfileContainer>
        <Result
          status="error"
          title={t("auth.participantProfile.errorFetchingDataMessage")}
          subTitle={participantHistoryDataError.message}
        />
      </ParticipantProfileContainer>
    );

  return (
    <ParticipantProfileContainer>
      {showAlert && (
        <Row gutter={[24, 40]} className="mb-3">
          <Col xs={24}>
            <Alert
              message={
                t("auth.participantProfile.registeredSuccessfullyMessage") +
                (showFullyBookedMessage
                  ? " " + t("auth.participantProfile.fullyBookedMessage")
                  : "")
              }
              type="success"
              closable
              onClose={() => setShowAlert(false)} // Close the alert when clicked
            />
          </Col>
        </Row>
      )}

      <Row gutter={[24, 40]} className="mb-5">
        <Col xs={24} lg={8}>
          <ApplyNowCard
            profileInfo={participantProfileData.participantProfile}
            showApplyNowBtn={false}
          />
        </Col>

        <Col xs={24} lg={16}>
          <Col xs={24}>
            <Title level={3} className="mb-0">
              {t("auth.participantProfile.historySectionTitle")}
            </Title>
          </Col>

          {participantHistoryData?.registrations?.length ? (
            participantHistoryData?.registrations?.map((obj) => (
              <HistoryCard
                key={obj.id}
                registrationObj={obj}
                refetchData={participantHistoryDataRefetch}
                gender={participantProfileData.participantProfile.gender}
              />
            ))
          ) : (
            <Result status="info" title={t("auth.participantProfile.noHistoryMessage")} />
          )}
        </Col>
      </Row>
    </ParticipantProfileContainer>
  );
};

export default ParticipantHistory;
