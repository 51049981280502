import { useState } from "react";
import { Button, Modal, Form, Input, Typography } from "antd";
import { SEND_PASSWORD_RESET_EMAIL_MUTATION } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const ForgotPassword = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const [sendPasswordResetEmailMutation] = useMutation(SEND_PASSWORD_RESET_EMAIL_MUTATION, {
    onCompleted: (data) => {
      if (data.sendPasswordResetEmail.success) {
        setErrorMessage(null);
        setSuccessMessage(t("auth.forgotPassword.forgotPasswordModal.linkSendSuccessfully"));
        setSubmitting(false);
      } else {
        setErrorMessage(data.sendPasswordResetEmail.errors[0]);
        setSuccessMessage(null);
        setSubmitting(false);
      }
    },
  });

  const handleFinish = async (values) => {
    setSubmitting(true);
    sendPasswordResetEmailMutation({
      variables: { ...values },
    });
  };

  return (
    <>
      <Text
        style={{
          fontSize: "16px",
          whiteSpace: "break-spaces",
          textAlign: "center",
          display: "block",
        }}
      >
        {t("auth.forgotPassword.forgotPasswordPrompt")}
        &nbsp;
        <Button
          type="link"
          className="font-bold"
          style={{ color: "#35b4a7", fontSize: "16px", padding: 0 }}
          onClick={handleOpenModal}
        >
          {t("auth.forgotPassword.forgotPasswordLink")}
        </Button>
      </Text>

      <Modal
        title={
          <span style={{ fontSize: "16px" }}>
            {t("auth.forgotPassword.forgotPasswordModal.title")}
            <p className="mt-3" style={{ fontSize: "14px" }}>
              {t("auth.forgotPassword.forgotPasswordModal.subTitle")}
            </p>
          </span>
        }
        open={isModalOpen}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Form onFinish={handleFinish}>
          {errorMessage && <p className="text-danger english-field">{errorMessage}</p>}
          {successMessage && <p className="text-success">{successMessage}</p>}

          <Form.Item
            label={t("auth.signUp.emailField")}
            name="email"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
              {
                type: "email",
                message: t("auth.signUp.validEmailAddressMessage"),
              },
            ]}
          >
            <Input className="english-field" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={submitting} loading={submitting}>
              {t("auth.forgotPassword.forgotPasswordModal.sendResetLinkBtn")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ForgotPassword;
