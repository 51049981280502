import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Layout, Card, Typography } from "antd";

import SignUpFormContainer from "./styles";

import CompanyForm from "./companyForm";
import ParticipantForm from "./participantForm";

const { Content } = Layout;
const { Title, Text } = Typography;

const SignUpForm = () => {
  const { t } = useTranslation();

  const { userType } = useParams();

  return (
    <SignUpFormContainer>
      <Layout className="layout-default ant-layout layout-sign-up">
        <Content className="pt-0">
          <Title
            className="mb-3 mt-0"
            style={{
              fontSize: "45px",
              whiteSpace: "break-spaces",
              textAlign: "center",
            }}
          >
            {t("auth.signUp.title")}
          </Title>

          <Card className="card-signup header-solid h-full ant-card pt-0" bordered="false">
            {userType === "participant" && <ParticipantForm />}

            {userType === "company" && <CompanyForm />}
          </Card>

          <Text
            style={{
              fontSize: "16px",
              whiteSpace: "break-spaces",
              textAlign: "center",
              display: "block",
            }}
          >
            {t("auth.signUp.signInPrompt")}

            <Link to="/sign-in" className="font-bold" style={{ color: "#35b4a7" }}>
              {t("auth.signUp.signInLink")}
            </Link>
          </Text>
        </Content>
      </Layout>
    </SignUpFormContainer>
  );
};
export default SignUpForm;
