import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { InputProps } from "antd/lib/input";

type SearchBarProps = InputProps;

const SearchBar = (props: SearchBarProps) => {
  return <Input addonBefore={<SearchOutlined />} {...props} />;
};

export default SearchBar;
