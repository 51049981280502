import { useMutation } from "@apollo/client";
import { Button, Form, Input, Space } from "antd";
import { SEND_CONTACT_US_MESSAGE } from "graphql/mutations";
import { useTranslation } from "react-i18next";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

type ContactUsFormProps = {
  handleClose: () => void;
  setIsRequestSuccessful: (isSuccessful: boolean) => void;
  setShouldShowAlert: (shouldShowAlert: boolean) => void;
  triggerRequestStatusMessage: () => void;
};

const ContactUsForm = ({
  handleClose,
  setIsRequestSuccessful,
  setShouldShowAlert,
  triggerRequestStatusMessage,
}: ContactUsFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [sendContactUsMessageMutation] = useMutation(SEND_CONTACT_US_MESSAGE, {
    onCompleted: (success: boolean) => {
      if (success) {
        setIsRequestSuccessful(true);
        onReset();
        triggerRequestStatusMessage();
      } else {
        setIsRequestSuccessful(false);
        setShouldShowAlert(true);
        triggerRequestStatusMessage();
      }
    },
  });

  const onFinish = (values: {
    name: string;
    subject: string;
    email: string;
    mobileNumber: string;
    message: string;
  }) => {
    sendContactUsMessageMutation({
      variables: {
        name: values.name,
        subject: values.subject,
        email: values.email,
        mobileNumber: values.mobileNumber,
        message: values.message,
      },
    });
  };

  const onReset = () => {
    form.resetFields();
    handleClose();
    setShouldShowAlert(true);
  };

  return (
    <Form form={form} name="control-hooks" onFinish={onFinish} style={{ maxWidth: 600 }}>
      <Form.Item
        name="name"
        label={t("home.contactUs.form.name")}
        rules={[{ required: true, message: t("auth.signUp.requiredFieldMessage") }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="subject"
        label={t("home.contactUs.form.subject")}
        rules={[{ required: true, message: t("auth.signUp.requiredFieldMessage") }]}
      >
        <Input />
      </Form.Item>

      <Form.Item name="email" label={t("home.contactUs.form.email")}>
        <Input />
      </Form.Item>

      <Form.Item
        name="mobileNumber"
        label={t("home.contactUs.form.mobileNumber")}
        rules={[{ required: true, message: t("auth.signUp.requiredFieldMessage") }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="message"
        label={t("home.contactUs.form.message")}
        rules={[{ required: true, message: t("auth.signUp.requiredFieldMessage") }]}
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            {t("home.contactUs.form.send")}
          </Button>

          <Button htmlType="button" onClick={onReset}>
            {t("home.contactUs.form.cancel")}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ContactUsForm;
