export enum RegistrationStatus {
  Pending = "PENDING",
  Approved = "APPROVED",
  Rejected = "REJECTED",
  Withdraw = "WITHDRAW",
}

export enum PaymentStatus {
  Pending = "PENDING",
  Paid = "PAID",
  Failed = "FAILED",
}

export type RegistrationListResponse = {
  id: number;

  createdAt: string;

  participantProfile: {
    id: number;
    participantId: string;
    fullNameEn: string;
    fullNameAr: string;
    civilIdNumber: string;
    mobileNumber: string;
    age: number;
    dateOfBirth: string;
    gender: string;
    user: {
      email: string;
    };
  };
  assignedJobOpportunity: {
    jobTitle: string;
    companyRequest: {
      companyNameEn: string;
      companyNameAr: string;
    };
  };
  status: RegistrationStatus;
  paymentStatus: PaymentStatus;
  valuesYouBelieveIn: string | null;
  howDidYouKnowAboutTheProgram: string | null;
  civilId: File | null;
  personalImage: File | null;
  ibanNumber: File | null;
  specialRequest: string | null;
  programRequirements: boolean;
  correctInformation: boolean;
  formType: string;
};

export type GetRegistrations = {
  registrations: RegistrationListResponse[];
  loading: boolean;
  error: unknown;
};
