import React from "react";
import { useTranslation } from "react-i18next";

import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  InputNumber,
  TimePicker,
  Card,
  Typography,
  Checkbox,
} from "antd";

import dayjs from "dayjs";

const { Title } = Typography;

const JobOpportunitiesForm = ({
  field,
  locationObjIndex,
  jobOpportunitiesList,
  setJobOpportunitiesList,
}) => {
  const initialValue = jobOpportunitiesList.map((obj) => {
    return {
      ...obj,
      startingTime: obj.startingTime ? dayjs(obj.startingTime, "HH:mm") : "",
      endingTime: obj.endingTime ? dayjs(obj.endingTime, "HH:mm") : "",
    };
  });

  const { t } = useTranslation();

  const handleFormChange = (index, eventName, eventValue) => {
    const data = [...jobOpportunitiesList];
    data[index][eventName] = eventValue;
    setJobOpportunitiesList(locationObjIndex, "jobOpportunities", data);
  };

  const addFields = () => {
    const newField = {
      jobTitle: "",
      jobDescription: "",
      malesNumber: "",
      femalesNumber: "",
      startingTime: "",
      endingTime: "",
      workDays: "",
      specialNotes: "",
    };
    const data = [...jobOpportunitiesList, newField];
    setJobOpportunitiesList(locationObjIndex, "jobOpportunities", data);
  };

  const removeFields = (index) => {
    const data = [...jobOpportunitiesList];
    data.splice(index, 1);
    setJobOpportunitiesList(locationObjIndex, "jobOpportunities", data);
  };

  return (
    <Form.List name={[field.name, "list"]} initialValue={initialValue}>
      {(subFields, { add, remove }) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: 16,
          }}
        >
          {subFields.map((subField, index) => (
            <React.Fragment key={subField.key}>
              <Card
                title={
                  t("requestForm.jobOpportunitiesForm.cardTitle") +
                  (index + 1) +
                  t("requestForm.jobOpportunitiesForm.inLocation") +
                  (locationObjIndex + 1)
                }
                extra={
                  "id" in jobOpportunitiesList[index] || subFields.length <= 1 ? null : (
                    <DeleteFilled
                      onClick={() => {
                        removeFields(index);
                        remove(subField.name);
                      }}
                    />
                  )
                }
                className={index === subFields.length - 1 ? "mb-4" : "mb-5"}
              >
                <Row gutter={[8, 8]}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      label={t("requestForm.jobOpportunitiesForm.jobTitleField")}
                      name={[index, "jobTitle"]}
                      rules={[
                        {
                          required: true,
                          message: t("requestForm.requiredFieldMessage"),
                        },
                      ]}
                    >
                      <Input
                        maxLength={50}
                        showCount
                        onChange={(event) =>
                          handleFormChange(index, "jobTitle", event.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={12}>
                    <Form.Item
                      name={[index, "jobDescription"]}
                      label={t("requestForm.jobOpportunitiesForm.jobDescriptionField")}
                      rules={[{ required: true, message: t("requestForm.requiredFieldMessage") }]}
                    >
                      <Input.TextArea
                        rows={1}
                        onChange={(event) =>
                          handleFormChange(index, "jobDescription", event.target.value)
                        }
                        style={{
                          height: "40px",
                          padding: "9px",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[8, 8]}>
                  <Col xs={24}>
                    <Title level={5}>
                      {t("requestForm.jobOpportunitiesForm.availableOpportunitiesNumberTitle")}
                    </Title>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name={[index, "malesNumber"]}
                      label={t("requestForm.jobOpportunitiesForm.malesNumberField")}
                      rules={[{ required: true, message: t("requestForm.requiredFieldMessage") }]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        onChange={(value) => handleFormChange(index, "malesNumber", value)}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name={[index, "femalesNumber"]}
                      label={t("requestForm.jobOpportunitiesForm.femalesNumberField")}
                      rules={[{ required: true, message: t("requestForm.requiredFieldMessage") }]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        onChange={(value) => handleFormChange(index, "femalesNumber", value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[8, 8]}>
                  <Col xs={24}>
                    <Title level={5}>
                      {t("requestForm.jobOpportunitiesForm.workingHoursTitle")}
                    </Title>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name={[index, "startingTime"]}
                      label={t("requestForm.jobOpportunitiesForm.startingTimeField")}
                      rules={[{ required: true, message: t("requestForm.requiredFieldMessage") }]}
                    >
                      <TimePicker
                        format="HH:mm"
                        use12Hours
                        style={{ width: "100%" }}
                        onChange={(timeObj, timeString) => {
                          handleFormChange(index, "startingTime", timeString);
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name={[index, "endingTime"]}
                      label={t("requestForm.jobOpportunitiesForm.endingTimeField")}
                      rules={[{ required: true, message: t("requestForm.requiredFieldMessage") }]}
                    >
                      <TimePicker
                        format="HH:mm"
                        use12Hours
                        style={{ width: "100%" }}
                        onChange={(timeObj, timeString) => {
                          handleFormChange(index, "endingTime", timeString);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[8, 8]}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name={[index, "workDays"]}
                      label={t("requestForm.jobOpportunitiesForm.workDays")}
                      rules={[{ required: true, message: t("requestForm.requiredFieldMessage") }]}
                    >
                      <Checkbox.Group
                        style={{ width: "100%" }}
                        onChange={(checkedValues) => {
                          handleFormChange(index, "workDays", checkedValues.join(", "));
                        }}
                      >
                        <Row className="mb-1 mx-0">
                          <Col>
                            <Checkbox value="Sunday">
                              {t("requestForm.jobOpportunitiesForm.sunday")}
                            </Checkbox>
                          </Col>
                        </Row>

                        <Row className="mb-1 mx-0">
                          <Col>
                            <Checkbox value="Monday">
                              {t("requestForm.jobOpportunitiesForm.monday")}
                            </Checkbox>
                          </Col>
                        </Row>

                        <Row className="mb-1 mx-0">
                          <Col>
                            <Checkbox value="Tuesday">
                              {t("requestForm.jobOpportunitiesForm.tuesday")}
                            </Checkbox>
                          </Col>
                        </Row>

                        <Row className="mb-1 mx-0">
                          <Col>
                            <Checkbox value="Wednesday">
                              {t("requestForm.jobOpportunitiesForm.wednesday")}
                            </Checkbox>
                          </Col>
                        </Row>

                        <Row className="mb-1 mx-0">
                          <Col>
                            <Checkbox value="Thursday">
                              {t("requestForm.jobOpportunitiesForm.thursday")}
                            </Checkbox>
                          </Col>
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[8, 8]}>
                  <Col xs={24} lg={16}>
                    <Form.Item
                      name={[index, "specialNotes"]}
                      label={t("requestForm.jobOpportunitiesForm.specialNotesField")}
                    >
                      <Input.TextArea
                        rows={5}
                        onChange={(event) =>
                          handleFormChange(index, "specialNotes", event.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>

              {index === subFields.length - 1 && (
                <Col xs={24} className="d-flex justify-content-center align-items-center">
                  <Button
                    onClick={() => {
                      addFields();
                      add();
                    }}
                    style={{ width: "100%" }}
                    icon={<PlusOutlined />}
                    className="custom-button"
                  >
                    {t("requestForm.jobOpportunitiesForm.addOpportunityButton")}
                  </Button>
                </Col>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </Form.List>
  );
};

export default JobOpportunitiesForm;
