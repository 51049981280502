import styled from "styled-components";

const ApplicationPanelFormContainer = styled.div`
  .ant-layout {
    background: #fff;
  }

  .layout-default .ant-layout-content {
    padding-top: 0;
  }

  .form-item-wrapper .ant-form-item-label {
    display: block !important;
    text-align: left;
  }

  .form-item-wrapper .ant-form-item-control {
    display: block;
  }

  .ant-input-number {
    width: 100%;
  }

  .ql-toolbar.ql-snow {
    text-align: left;
    direction: ltr;
    font-family: "Rubik", sans-serif;
  }

  .ql-editor {
    text-align: right;
    direction: rtl;
    font-family: AirStrip;
  }

  .ql-editor ul > li::before {
    font-family: "Rubik", sans-serif;
  }

  .ql-editor ::before {
    padding-left: 40px;
  }

  .ql-toolbar {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .ql-container {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

export default ApplicationPanelFormContainer;
