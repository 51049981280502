import styled from "styled-components";

const SignInFormContainer = styled.div`
  .ant-layout {
    background: transparent;
  }

  span.ant-input-password {
    padding: 0 11px;
  }

  input#password {
    padding-top: 4px;
    padding-bottom: 4px;
  }
`;

export default SignInFormContainer;
