import React from "react";
import { useTranslation } from "react-i18next";

import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { Form, Input, Row, Col, Button, Card, Checkbox } from "antd";

const TeamMembersFormList = ({ inputFields, setInputFields, data }) => {
  const { t } = useTranslation();

  const handleFormChange = (index, eventName, eventValue) => {
    const data = [...inputFields];
    data[index][eventName] = eventValue;
    setInputFields(data);
  };

  const addFields = () => {
    const newField = {
      fullNameEn: "",
      fullNameAr: "",
      jobTitleEn: "",
      jobTitleAr: "",
      image: null,
      active: false,
    };
    setInputFields([...inputFields, newField]);
  };

  const removeFields = async (index) => {
    const data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  return (
    <Form.List name="teamMembersForm" initialValue={inputFields}>
      {(fields, { add, remove }) => {
        return fields.map((field, index) => (
          <React.Fragment key={field.key}>
            <Card
              title={t("dashboard.landingPageSettings.teamMembersSection.cardTitle") + (index + 1)}
              extra={
                "id" in inputFields[index] ? null : (
                  <DeleteFilled
                    onClick={() => {
                      removeFields(index);
                      remove(field.name);
                    }}
                  />
                )
              }
              className={index === fields.length - 1 ? "mb-4" : "mb-5"}
            >
              <Row gutter={[8, 8]}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t("dashboard.landingPageSettings.teamMembersSection.fullNameEn")}
                    name={[index, "fullNameEn"]}
                    rules={[
                      {
                        required: true,
                        message: t("requestForm.requiredFieldMessage"),
                      },
                    ]}
                  >
                    <Input
                      maxLength={50}
                      showCount
                      onChange={(event) =>
                        handleFormChange(index, "fullNameEn", event.target.value)
                      }
                      className="english-field"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t("dashboard.landingPageSettings.teamMembersSection.fullNameAr")}
                    name={[index, "fullNameAr"]}
                    rules={[
                      {
                        required: true,
                        message: t("requestForm.requiredFieldMessage"),
                      },
                    ]}
                  >
                    <Input
                      maxLength={50}
                      showCount
                      onChange={(event) =>
                        handleFormChange(index, "fullNameAr", event.target.value)
                      }
                      className="arabic-field"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[8, 8]}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t("dashboard.landingPageSettings.teamMembersSection.jobTitleEn")}
                    name={[index, "jobTitleEn"]}
                    rules={[
                      {
                        required: true,
                        message: t("requestForm.requiredFieldMessage"),
                      },
                    ]}
                  >
                    <Input
                      maxLength={50}
                      showCount
                      onChange={(event) =>
                        handleFormChange(index, "jobTitleEn", event.target.value)
                      }
                      className="english-field"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t("dashboard.landingPageSettings.teamMembersSection.jobTitleAr")}
                    name={[index, "jobTitleAr"]}
                    rules={[
                      {
                        required: true,
                        message: t("requestForm.requiredFieldMessage"),
                      },
                    ]}
                  >
                    <Input
                      maxLength={50}
                      showCount
                      onChange={(event) =>
                        handleFormChange(index, "jobTitleAr", event.target.value)
                      }
                      className="arabic-field"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[8, 8]}>
                <Col xs={24}>
                  <Form.Item
                    label={
                      <>
                        {t("dashboard.landingPageSettings.teamMembersSection.image")}
                        {"id" in inputFields[index] && (
                          <>
                            &nbsp;
                            <a href={data[index].image} target="_blank" rel="noopener noreferrer">
                              (
                              {t(
                                "dashboard.landingPageSettings.teamMembersSection.viewCurrentImage",
                              )}
                              )
                            </a>
                          </>
                        )}
                      </>
                    }
                    name={[index, "image"]}
                    rules={[
                      {
                        required: !("id" in inputFields[index]),
                        message: t("auth.signUp.requiredFieldMessage"),
                      },
                    ]}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(event) => handleFormChange(index, "image", event.target.files[0])}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[8, 8]}>
                <Col xs={24}>
                  <Form.Item
                    label={t("dashboard.landingPageSettings.teamMembersSection.active")}
                    name={[index, "active"]}
                  >
                    <Checkbox
                      checked={inputFields[index].active}
                      onChange={(event) => handleFormChange(index, "active", event.target.checked)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            {index === fields.length - 1 && (
              <Row gutter={[8, 8]} justify="center">
                <Col xs={24} className="mb-4">
                  <Button
                    onClick={() => {
                      addFields();
                      add();
                    }}
                    style={{ width: "100%" }}
                    icon={<PlusOutlined />}
                  />
                </Col>
              </Row>
            )}
          </React.Fragment>
        ));
      }}
    </Form.List>
  );
};
export default TeamMembersFormList;
