import React from "react";
import { useTranslation } from "react-i18next";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { Form, Input, Row, Col, Button, Card, Typography } from "antd";

const { Title } = Typography;

const InterviewersInfoForm = ({ inputFields, setInputFields }) => {
  const { t } = useTranslation();

  const handleFormChange = (index, eventName, eventValue) => {
    const data = [...inputFields];
    data[index][eventName] = eventValue;
    setInputFields(data);
  };

  const addFields = () => {
    const newField = { name: "", number: "" };
    setInputFields([...inputFields, newField]);
  };

  const removeFields = async (index) => {
    const data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  return (
    <>
      <Title
        className="mb-3 mt-0"
        style={{
          fontSize: "14px",
          whiteSpace: "break-spaces",
          color: "#35b4a7",
          fontWeight: 700,
        }}
      >
        {t("requestForm.collapseHeader.interviewersInfo")}
      </Title>

      <Form.List name="interviewersInfoForm" initialValue={inputFields}>
        {(fields, { add, remove }) => {
          return fields.map((field, index) => (
            <React.Fragment key={field.key}>
              <Card
                title={t("requestForm.interviewersInfoForm.cardTitle") + (index + 1)}
                extra={
                  "id" in inputFields[index] ? null : (
                    <DeleteFilled
                      onClick={() => {
                        removeFields(index);
                        remove(field.name);
                      }}
                    />
                  )
                }
                className={index === fields.length - 1 ? "mb-4" : "mb-5"}
              >
                <Row gutter={[8, 8]}>
                  <Col md={12} xs={24}>
                    <Form.Item
                      name={[index, "name"]}
                      label={t("requestForm.interviewersInfoForm.nameField")}
                      rules={[{ required: true, message: t("requestForm.requiredFieldMessage") }]}
                    >
                      <Input
                        onChange={(event) => handleFormChange(index, "name", event.target.value)}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={12} xs={24}>
                    <Form.Item
                      name={[index, "number"]}
                      label={t("requestForm.interviewersInfoForm.numberField")}
                      rules={[{ required: true, message: t("requestForm.requiredFieldMessage") }]}
                    >
                      <PhoneInput
                        className="english-field"
                        country="kw"
                        placeholder=""
                        excludeCountries={["il"]}
                        preferredCountries={["kw"]}
                        onChange={(value, data) => {
                          const countryCode = data.dialCode;
                          const countryCodeIndex = value.indexOf(countryCode);
                          const length = countryCode.length;
                          const number = countryCode + "-" + value.slice(countryCodeIndex + length);
                          handleFormChange(index, "number", number);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>

              {index === fields.length - 1 && (
                <Col xs={24} className="d-flex justify-content-center align-items-center mb-3 mt-0">
                  <Button
                    onClick={() => {
                      addFields();
                      add();
                    }}
                    style={{ width: "100%" }}
                    icon={<PlusOutlined />}
                    className="custom-button"
                  >
                    {t("requestForm.interviewersInfoForm.addInterviewerButton")}
                  </Button>
                </Col>
              )}
            </React.Fragment>
          ));
        }}
      </Form.List>
    </>
  );
};

export default InterviewersInfoForm;
