import styled from "styled-components";
import bg from "../../../assets/images/circlebg-green.svg";

const HeroSectionContainer = styled.div`
  .ant-layout {
    background: transparent;
  }

  .title-underline {
    border-bottom: 2px solid #000;
  }

  img {
    width: 100%;
    display: block;
  }

  @media (min-width: 992px) {
    /* Large devices (lg) and above */

    background-image: url(${bg});
    background-size: 120% 120%;
    background-position: center;
    background-repeat: no-repeat;

    .img-col {
      display: flex;
      justify-content: flex-end;
    }

    img {
      max-width: 550px;
    }
  }

  .ant-btn {
    font-size: 16px;
  }
`;

export default HeroSectionContainer;
