import React from "react";
import { Carousel } from "antd";

const LogoCarousel = ({ data }) => {
  return (
    <Carousel slidesToShow={4} dots={false} autoplay>
      {data.map((obj) => (
        <div className="w-auto px-2 px-md-5" key={obj.id}>
          <img
            src={obj.logo}
            alt={obj.name}
            style={{
              width: "70%",
              height: "45px",
              objectFit: "contain",
            }}
          />
        </div>
      ))}
    </Carousel>
  );
};

export default LogoCarousel;
