import { useQuery } from "@apollo/client";
import { Col, Result, Row, Alert } from "antd";
import useAuth from "auth";
import { Loading } from "components/Loading";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { PARTICIPANT_PROFILE_INFORMATION } from "../../../graphql/queries";

import ApplyNowCard from "./ApplyNowCard";
import ProfileInfoCard from "./ProfileInfoCard";
import ParticipantProfileContainer from "./styles";

const ParticipantProfile = () => {
  const { t } = useTranslation();

  const { userLoading, user } = useAuth();

  const [showAlert, setShowAlert] = useState(false);

  const { userID } = useParams();

  const {
    loading: participantProfileDataLoading,
    error: participantProfileDataError,
    data: participantProfileData,
    refetch: participantProfileDataRefetch,
  } = useQuery(PARTICIPANT_PROFILE_INFORMATION, {
    variables: { userId: parseInt(userID) },
  });

  useEffect(() => {
    const emailVerifiedSuccessfullyMessage = localStorage.getItem(
      "emailVerifiedSuccessfullyMessage",
    );
    if (emailVerifiedSuccessfullyMessage === "true") {
      setShowAlert(true);

      localStorage.removeItem("emailVerifiedSuccessfullyMessage");

      // Automatically hide the alert after 5 seconds
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
  }, []);

  if (participantProfileDataLoading || userLoading) return <Loading />;

  if (participantProfileDataError)
    return (
      <ParticipantProfileContainer>
        <Result
          status="error"
          title={t("auth.participantProfile.errorFetchingDataMessage")}
          subTitle={participantProfileDataError.message}
        />
      </ParticipantProfileContainer>
    );

  return (
    <ParticipantProfileContainer>
      {showAlert && (
        <Row gutter={[24, 40]} className="mb-3">
          <Col xs={24}>
            <Alert
              message={t("auth.accountVerification.emailVerifiedSuccessfullyMessage")}
              type="success"
              closable
              onClose={() => setShowAlert(false)} // Close the alert when clicked
            />
          </Col>
        </Row>
      )}

      <Row gutter={[24, 40]} className="mb-5">
        <Col xs={24} lg={8}>
          <ApplyNowCard
            profileInfo={participantProfileData.participantProfile}
            showApplyNowBtn={true}
          />
        </Col>

        <Col xs={24} lg={16}>
          <ProfileInfoCard
            profileInfo={participantProfileData.participantProfile}
            sociallyAuthenticatedUserEmail={user?.email}
            userID={userID}
            participantProfileDataRefetch={participantProfileDataRefetch}
          />
        </Col>
      </Row>
    </ParticipantProfileContainer>
  );
};

export default ParticipantProfile;
