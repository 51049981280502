import { ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import Cookies from "js-cookie";
import { useMemo } from "react";

let apolloClient;

const getAuthLink = () => {
  return setContext((_, { headers }) => {
    //   TO DO: use refresh token.
    const token = Cookies.get("token");

    const languageFromLocalStorage = localStorage.getItem("i18nextLng");

    const language = languageFromLocalStorage || "ar";

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
        "Accept-Language": language,
      },
    };
  });
};

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  credentials: "same-origin",
});

function createApolloClient() {
  const authLink = getAuthLink();

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
}

export function initializeApollo() {
  const _apolloClient = createApolloClient();

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
